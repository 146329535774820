<script setup>
</script>

<template>
  <crud-details-page
    api="unlimited-roll-event"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Notification -->
        <crud-field-selector-client-strings
          api-field-name="Notification"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
