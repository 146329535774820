<template>
  <crud-details-page
    api="cosmetics/skin-group"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.SkinGroupType"
          :visible-options="['SkinGroup']"
          required
        />
        <!-- AssetIcon -->
        <crud-field-selector-files
          api-field-name="AssetIcon"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
