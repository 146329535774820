<script setup></script>

<template>
  <crud-details-page
    api="achievements/achievement"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="7">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.AchievementType"
          required
        />
        <!-- RequiredValue -->
        <crud-field-number api-field-name="RequiredValue" />
        <!-- NameTag -->
        <crud-field-selector-client-strings api-field-name="NameTag" />
      </fields-col>
      <fields-col :sm="5">
        <!-- XpPct -->
        <crud-field-number api-field-name="XpPct" />
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
        <!-- RewardPoints -->
        <crud-field-number api-field-name="RewardPoints" />
      </fields-col>
      <fields-col :sm="12">
        <template
          v-for="i in 2"
          :key="i"
        >
          <crud-field-selector-skin
            :sm="16"
            :api-field-name="`Skin${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Skin${i}Amount`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
