<script>
import CrudFieldSelectorTrophy from '@/components/forms/crud-fields/CrudFieldSelectorTrophy.vue'

export default {
  components: { CrudFieldSelectorTrophy }
}
</script>

<template>
  <crud-details-page
    api="rewards/tournament-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="6">
        <!-- SeasonNo -->
        <crud-field-number api-field-name="SeasonNo" />
        <!-- No -->
        <crud-field-number api-field-name="No" />
        <!-- TournamentMode -->
        <crud-field-select
          api-field-name="TournamentMode"
          options-enum="enums.TournamentMode"
        />
        <!-- Trophy -->
        <CrudFieldSelectorTrophy api-field-name="Trophy" />
        <!-- MountGroupType -->
        <crud-field-select
          api-field-name="MountGroupType"
          options-enum="enums.MountGroupType"
        />
        <!-- MountGroupTypeDurationDays -->
        <crud-field-number api-field-name="MountGroupTypeDurationDays" />
      </fields-col>
      <section-divider>Reward Tiers</section-divider>
      <template
        v-for="k in 4"
        :key="k"
      >
        <section-divider>Tier {{ k - 1 }}</section-divider>
        <fields-col :sm="7">
          <!-- Energy -->
          <crud-field-number :api-field-name="`Tier${k - 1}Reward.Energy`" />
          <!-- Gems -->
          <crud-field-number :api-field-name="`Tier${k - 1}Reward.Gems`" />
          <!-- XpPct -->
          <crud-field-number :api-field-name="`Tier${k - 1}Reward.XpPct`" />
        </fields-col>
        <fields-col :sm="12">
          <template
            v-for="i in 6"
            :key="i"
          >
            <crud-field-selector-skin
              :sm="16"
              :api-field-name="`Tier${k - 1}Reward.Skin${i}`"
            />
            <crud-field-number
              :sm="8"
              :api-field-name="`Tier${k - 1}Reward.Skin${i}Amount`"
            />
          </template>
        </fields-col>
      </template>
    </template>
  </crud-details-page>
</template>
