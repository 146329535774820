<script>
import CrudFieldSelectorCardEvent from '@/components/forms/crud-fields/CrudFieldSelectorCardEvent.vue'

export default {
  components: {
    CrudFieldSelectorCardEvent
  }
}
</script>

<template>
  <crud-details-page
    api="card-exchange-event"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- CardExchangeType -->
        <crud-field-select
          api-field-name="CardExchangeType"
          options-enum="enums.CardExchangeType"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetHomeIcon -->
        <crud-field-selector-files api-field-name="AssetHomeIcon" />
        <!-- DurationHours -->
        <crud-field-number api-field-name="DurationHours" />
      </fields-col>
      <!-- AssetHomeIcon -->
      <CrudFieldSelectorCardEvent
        :sm="8"
        api-field-name="CardEvent"
      />
    </template>
  </crud-details-page>
</template>
