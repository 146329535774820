<script>
export default {
  data() {
    return {
      messageQueue: [],
      msgQueue: []
    }
  },
  mounted() {
    this.loadMessageQueue()
  },
  methods: {
    loadMessageQueue() {
      this.$axios
        .get(`/admin/api/proxy/request/`, {
          params: { method: 'GET', data: [], uri: '/proxy/tools/globalmessagesqueue/', env: 'prod' }
        })
        .then((results) => {
          this.messageQueue = results.data
        })
        .catch(() => {
          this.$message.error('Error while getting message queue')
        })
    }
  }
}
</script>

<template>
  <crud-table
    :default-visible-columns="['CreatedAt', 'Title', 'Sender']"
    api="tools/template-global-messages"
    api-item="tools/template-global-message"
    :versioned="false"
  >
    <template #topTable>
      <div class="flex flex-row flex-wrap items-center">
        <div
          v-for="(msg, i) in messageQueue"
          :key="i"
          class="inline-block rounded-lg bg-slate-300 p-2 mx-2 mb-2 whitespace-nowrap"
        >
          <p>
            <strong>{{ msg.env }}</strong>
          </p>
          <p>{{ msg.title }} (#{{ msg.template_id }})</p>

          <p v-if="msg.sent === 1">
            Sent!
          </p>
          <p
            v-for="(sdate, j) in msg.send_dates"
            :key="j"
          >
            {{ sdate.zone }} - {{ sdate.time }}
          </p>
        </div>
      </div>
    </template>
  </crud-table>
</template>
