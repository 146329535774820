<script>
import CrudFieldSelectorChest from '@/components/forms/crud-fields/CrudFieldSelectorChest.vue'

export default {
  components: { CrudFieldSelectorChest }
}
</script>

<template>
  <crud-details-page
    api="chests/new-player-chest"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- SeasonDayStart -->
        <crud-field-number
          :sm="10"
          api-field-name="SeasonDayStart"
          required
        />
        <!-- Chest1 -->
        <CrudFieldSelectorChest
          api-field-name="Chest1"
          required
        />
        <!-- Chest2 -->
        <CrudFieldSelectorChest
          api-field-name="Chest2"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
