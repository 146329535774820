<script setup></script>

<template>
  <crud-details-page
    api="tools/group-notification"
    disable-top-margin
    :versioned="false"
  >
    <template #form>
      <fields-col :sm="10">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- IsEnabled -->
        <crud-field-switcher api-field-name="IsEnabled" />
        <!-- PostOnSlackSwChangelog -->
        <crud-field-switcher api-field-name="PostOnSlackSwChangelog" />
      </fields-col>
      <fields-col :sm="4">
        <!-- ValidToSeasonNo -->
        <crud-field-number api-field-name="ValidToSeasonNo" />
        <!-- SeasonDay -->
        <crud-field-number
          api-field-name="SeasonDay"
          label="Send at season day"
        />
        <!-- Hour -->
        <crud-field-number
          api-field-name="Hour"
          label="At hour of season day"
        />
        <!-- Minute -->
        <crud-field-number
          api-field-name="Minute"
          label="At minute of season day"
        />
      </fields-col>
      <fields-col :sm="10">
        <!-- GuildRestriction -->
        <crud-field-select
          api-field-name="GuildRestriction"
          options-enum="enums.NotificationGuildRestriction"
          required
        />
        <!-- Message -->
        <crud-field-selector-client-strings
          api-field-name="Message"
          required
        />
      </fields-col>
      <section-divider>Notification sent states</section-divider>
      <fields-col :sm="3">
        <!-- AsiaDone -->
        <crud-field-switcher
          api-field-name="AsiaDone"
          disabled
        />
        <!-- EuropeDone -->
        <crud-field-switcher
          api-field-name="EuropeDone"
          disabled
        />
        <!-- AmericaDone -->
        <crud-field-switcher
          api-field-name="AmericaDone"
          disabled
        />
      </fields-col>
      <fields-col :sm="5">
        <!-- AsiaLastId -->
        <crud-field-number
          api-field-name="AsiaLastId"
          disabled
        />
        <!-- EuropeLastId -->
        <crud-field-number
          api-field-name="EuropeLastId"
          disabled
        />
        <!-- AmericaLastId -->
        <crud-field-number
          api-field-name="AmericaLastId"
          disabled
        />
      </fields-col>
      <fields-col :sm="5">
        <!-- AsiaSentCounter -->
        <crud-field-number
          api-field-name="AsiaSentCounter"
          disabled
        />
        <!-- EuropeSentCounter -->
        <crud-field-number
          api-field-name="EuropeSentCounter"
          disabled
        />
        <!-- AmericaSentCounter -->
        <crud-field-number
          api-field-name="AmericaSentCounter"
          disabled
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
