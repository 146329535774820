<script setup>
</script>

<template>
  <crud-details-page
    api="cosmetics/custom-dice"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.MoveType"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- Asset -->
        <crud-field-selector-files api-field-name="Asset" />
        <!-- AssetWinSound -->
        <crud-field-selector-files api-field-name="AssetWinSound" />
        <!-- AssetWinBigSound -->
        <crud-field-selector-files api-field-name="AssetWinBigSound" />
        <!-- DurationHours -->
        <crud-field-number
          api-field-name="DurationHours"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
