<script>
import CrudFieldSelectorSkin from '@/components/forms/crud-fields/CrudFieldSelectorSkin.vue'
import CrudFieldSelectorChest from '@/components/forms/crud-fields/CrudFieldSelectorChest.vue'

export default {
  components: { CrudFieldSelectorChest, CrudFieldSelectorSkin }
}
</script>

<template>
  <crud-details-page
    api="chests/lootbox"
    disable-top-margin
  >
    <template #form>
      <!-- Name -->
      <crud-field-text
        :sm="8"
        api-field-name="Label"
        required
      />
      <section-divider>Skins</section-divider>
      <template
        v-for="i in 20"
        :key="i"
      >
        <CrudFieldSelectorSkin
          :sm="8"
          :api-field-name="`Skin${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Skin${i}Quantity`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Skin${i}Limit`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Skin${i}Price`"
        />
      </template>
      <section-divider>Chests</section-divider>
      <template
        v-for="i in 10"
        :key="i"
      >
        <CrudFieldSelectorChest
          :sm="8"
          :api-field-name="`Chest${i}`"
          :init-filters="{ Type: 'MysteryChest' }"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Chest${i}Limit`"
        />
        <el-col :sm="12" />
      </template>
    </template>
  </crud-details-page>
</template>
