<script>
import EnvSelector from '@/components/proxy/EnvSelector.vue'

export default {
  components: { EnvSelector },
  data() {
    return {
      hideColumns: [
        { value: 'date', label: 'Date' },
        { value: 'pid', label: 'Player' },
        { value: 'action', label: 'Action' },
        { value: 'xp', label: 'Xp' },
        { value: 'energy', label: 'Energy' },
        { value: 'shields', label: 'Shields' },
        { value: 'gems', label: 'Gems' },
        { value: 'season_pass_points', label: 'SeasonPassPoints' },
        { value: 'card_points', label: 'CardPoints' },
        { value: 'duplicate_points', label: 'DuplicatePoints' },
        { value: 'gcoins', label: 'GCoins' },
        { value: 'skins', label: 'Skins' }
      ],
      prevPageByte: 0,
      searchFields: {
        showResponseModel: false,
        prettyPrint: false,
        allFiles: false,
        hideColumns: []
      },
      disableSearching: true,
      resourceType: [
        { value: 'DeltaXP', label: 'Xp' },
        { value: 'DeltaEnergy', label: 'Energy' },
        { value: 'DeltaShields', label: 'Shields' },
        { value: 'DeltaGems', label: 'Gems' },
        { value: 'DeltaSeasonPassPoints', label: 'SeasonPassPoints' },
        { value: 'DeltaCardPoints', label: 'CardPoints' },
        { value: 'DeltaDuplicatePoints', label: 'DuplicatePoints' },
        { value: 'DeltaGCoins', label: 'GCoins' },
        { value: 'DeltaSkins', label: 'Skins' }
      ],
      giftLayouts: [
        { value: 'normal', label: 'Normal' },
        { value: 'battle', label: 'Battle' },
        { value: 'battletournament', label: 'Battle Tournament' },
        { value: 'facebook', label: 'Facebook' },
        { value: 'league', label: 'League Reward' },
        { value: 'seasonrank', label: 'Season Ranking Rewards' },
        { value: 'gtrophy', label: 'Guild Trophy' },
        { value: 'ptrophy', label: 'Player Trophy' },
        { value: 'aclaimedbattle', label: 'Auto Claimed Battle rewards' },
        { value: 'aclaimedspass', label: 'Auto Claimed Season Rewards' },
        { value: 'normal2d', label: 'Autoclaimed 2 days expire' },
        { value: 'ebattle', label: 'Boss Event' },
        { value: 'tournamentpromotion', label: 'Tournament Promotion' },
        { value: 'tournamentfinished', label: 'Tournament Finished' },
        { value: 'carddeck', label: 'Card Deck' },
        { value: 'skinsent', label: 'Skin Sent' },
        { value: 'trainingfinished', label: 'Training Finished' },
        { value: 'hitthreeeventmilestone', label: 'Hit Three Event Milestone' },
        { value: 'hitthreeeventranking', label: 'Hit Three Event Ranking' },
        { value: 'cardevent', label: 'Card Event' },
        { value: 'bosseventglobal', label: 'Boss Event Global' },
        { value: 'pvechallenge', label: 'Pve Challenge' },
        { value: 'hitthreeeventglobal', label: 'Hit Three Event Global' },
        { value: 'lottery', label: 'Lottery' },
        { value: 'jokercard', label: 'Joker' },
        { value: 'tutorial', label: 'Tutorial' },
        { value: 'achievement', label: 'Achievement' },
        { value: 'lotteryeventranking', label: 'Lottery Event Ranking' },
        { value: 'lotteryeventglobal', label: 'Lottery Event Global' },
        { value: 'stones', label: 'Stones' }
      ],
      resourcesActions: [
        { value: 'logged/battle/move', label: 'Battle move' },
        { value: 'logged/tournament/move', label: 'Tournament move' },
        { value: 'logged/bossevent/move', label: 'BossEvent move' },
        { value: 'logged/battle/tutorial', label: 'Tutorial move' },
        { value: 'logged/pvechallenge/move', label: 'PveChallenge move' },
        { value: 'logged/pvechallenge/start', label: 'PveChallenge start' },
        { value: 'GIFT SEND', label: 'Gifts Sent to player' },
        { value: 'logged/player/collectgift', label: 'Gifts collected by player (action)' },
        { value: 'GIFT COLLECTED', label: 'Gifts collected by player (gift details)' },
        { value: 'logged/player/openchest', label: 'Open chest' },
        { value: 'logged/player/openspin', label: 'Open spin' },
        { value: 'logged/player/openpack', label: 'Open pack' },
        { value: 'logged/player/openlottery', label: 'Open lottery' },
        { value: 'logged/player/openwheel', label: 'Open wheel' },
        { value: 'logged/player/requestskin', label: 'Request skin' },
        { value: 'logged/player/sendskin', label: 'Send skin' },
        { value: 'logged/player/exchangeduplicates', label: 'Exchange duplicates' },
        { value: 'logged/player/resetexchangetimer', label: 'Reset exchange timer' },
        { value: 'logged/battle/claimskirmishenergy', label: 'Claim Skirmish Energy - battle' },
        { value: 'logged/tournament/claimskirmishenergy', label: 'Claim Skirmish Energy - tournament' },
        { value: 'logged/player/collectdailyreward', label: 'Collect daily reward' },
        { value: 'logged/player/collectfacebookfriendsreward', label: 'Collect facebook reward' },
        { value: 'logged/player/collectseasonpass', label: 'Collect season pass' },
        { value: 'logged/player/usedeeplink', label: 'Collect deeplink' },
        { value: 'logged/player/collectachievement', label: 'Collect achievement' },
        { value: 'logged/player/collectachievementsgroup', label: 'Collect achievements group' },
        { value: 'logged/player/shopbuy', label: 'Energy bought' },
        { value: 'logged/player/duplicateshopbuy', label: 'Duplicate shop buy' },
        { value: 'logged/offer/freeverify', label: 'Free offer bought' },
        { value: 'logged/offer/addpending', label: 'Added offer to pending payments' },
        { value: 'logged/offer/androidverify', label: 'Android - confirmed offer buy' },
        { value: 'logged/offer/iosverify', label: 'IOS - confirmed offer buy' },
        { value: 'logged/player/collectstorepurchase', label: 'Collect external store purchases' },
        { value: 'EXTERNAL STORE BUY', label: 'External store - confirmed offer buy' },
        { value: 'EXTERNAL STORE FREE OFFER', label: 'External store - free offer buy' },
        { value: 'EXTERNAL STORE BUY WITH GCOINS', label: 'External store - buy with gcoins' },
        { value: 'logged/player/forgeupgrade', label: 'Forge upgrade' },
        { value: 'logged/player/mountskin', label: 'Mount skin' },
        { value: 'logged/messages/collect', label: 'Message collect' },
        { value: 'logged/guild/takeover', label: 'Guild takeover' },
        { value: 'logged/player/addrate', label: 'Add Rate' }
      ],
      searchFiles: [],
      loadingFiles: {}, // env -> bool
      api: '',
      dev: false
    }
  },
  computed: {
    isOnFirstPage() {
      return typeof this.$route.query?.c_sea?.nextPage === 'undefined' || this.$route.query?.c_sea?.nextPage <= 0
    }
  },
  mounted() {
    if (this.searchFields?.env && this.searchFields?.searchFile) {
      this.getFileLogs()
    }
  },
  methods: {
    getFileLogs() {
      const api = 'proxy/request'
      const selectedEnv = this.searchFields.env
      if (this.loadingFiles[selectedEnv]) {
        return
      }
      if (typeof selectedEnv === 'undefined' || this.dev) {
        return
      }
      this.api = ''
      this.loadingFiles[selectedEnv] = true
      this.searchFiles = []
      const envUrl = this.$store.getters['auth/envs'][selectedEnv]

      this.$axios
        .get(`${envUrl}/proxy/gameplay/playerresourceslogfiles/`)
        .then((resp) => {
          let isSearchFile = false
          for (const i in resp.data.files) {
            this.searchFiles.push({
              value: resp.data.files[i],
              label: resp.data.files[i]
            })
            if (this.searchFields.searchFile === resp.data.files[i]) {
              isSearchFile = true
            }
          }
          if (!isSearchFile) {
            this.searchFields.searchFile = this.searchFiles?.[0]?.value || ''
          }
          this.api = api
          this.loadingFiles[selectedEnv] = false
          this.$refs.crudTable.fetchData()
        })
        .catch(() => {
          this.$message.error('Error while getting dates')
        })
    },
    prevPage() {
      this.searchFields.nextPage = this.prevPageByte
    },
    nextPage(currPageByte, nextPageByte, skipFiles) {
      this.prevPageByte = currPageByte
      this.searchFields.nextPage = nextPageByte
      this.searchFields.skipFiles = skipFiles
    },
    clearNextPage() {
      this.searchFields.nextPage = null
      this.searchFields.skipFiles = null
    },
    changeEnv() {
      this.getFileLogs()
    },
    onClearSearchFields(clearSearchFields) {
      clearSearchFields(this.searchFields, ['env', 'searchFile'])
      const isSearchFile = this.searchFiles.some((option) => {
        return option.value === this.searchFields.searchFile
      })

      if (!isSearchFile) {
        this.searchFields.searchFile = this.searchFiles?.[0]?.value || ''
      }
      this.searchFields.showResponseModel = false
      this.searchFields.prettyPrint = false
    }
  }
}
</script>

<template>
  <crud-table
    ref="crudTable"
    :api="api"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/playerresourceslogs/'
    }"
    :top-actions="{
      excelExport: false,
      importExcel: false,
      addNew: false
    }"
    disable-pagination
    hide-columns-selector
    disable-multi-select-rows
    :search-fields="searchFields"
    @clear-search-data="onClearSearchFields"
  >
    <template #topLeft>
      <div class="flex align-middle">
        <div class="w-24">
          <EnvSelector
            v-model:value-field="searchFields.env"
            @change="changeEnv"
          />
        </div>
        <div>
          <crud-field-switcher
            api-field-name="showResponseModel"
            :form="searchFields"
            :external-errors="{}"
            label="Response"
            active-text="&nbsp;&nbsp;Show&nbsp;&nbsp;"
            inactive-text="&nbsp;&nbsp;Hide&nbsp;&nbsp;"
            inactive-color="#13ce66"
            active-color="rgb(67 56 202)"
            filter-mode
            slim
            size="default"
            class-component="ml-0 mt-1 items-start"
          />
        </div>
        <div>
          <crud-field-switcher
            api-field-name="prettyPrint"
            :form="searchFields"
            :external-errors="{}"
            label="Pretty print"
            active-text="&nbsp;&nbsp;Yes&nbsp;&nbsp;"
            inactive-text="&nbsp;&nbsp;No&nbsp;&nbsp;"
            inactive-color="#13ce66"
            active-color="rgb(67 56 202)"
            filter-mode
            slim
            size="default"
            class-component="ml-0 mt-1 items-start"
          />
        </div>
        <div>
          <crud-field-select
            api-field-name="hideColumns"
            :form="searchFields"
            :external-errors="{}"
            filter-mode
            slim
            multiple
            use
            collapse-tags
            class-component="min-w-[120px]"
            :options="hideColumns"
            label="Hide Columns"
          />
        </div>
        <div>
          <crud-field-text
            api-field-name="custom"
            :form="searchFields"
            :external-errors="{}"
            label="Custom"
            filter-mode
            slim
            class-component="min-w-[130px]"
            @change="clearNextPage"
          />
        </div>
        <div>
          <crud-field-text
            api-field-name="PID"
            :form="searchFields"
            :external-errors="{}"
            label="Player"
            filter-mode
            slim
            class-component="min-w-[120px]"
            @change="clearNextPage"
          />
        </div>
        <div>
          <crud-field-select
            api-field-name="Action"
            :form="searchFields"
            :external-errors="{}"
            filter-mode
            slim
            class-component="min-w-[130px]"
            :options="resourcesActions"
            @change="clearNextPage"
          />
        </div>
        <div>
          <crud-field-select
            api-field-name="resource"
            :form="searchFields"
            :external-errors="{}"
            filter-mode
            slim
            class-component="min-w-[130px]"
            :options="resourceType"
            @change="clearNextPage"
          />
        </div>
        <div>
          <crud-field-select
            api-field-name="layout"
            :form="searchFields"
            :external-errors="{}"
            filter-mode
            slim
            class-component="min-w-[130px]"
            :options="giftLayouts"
            @change="clearNextPage"
          />
        </div>
        <div>
          <crud-field-text
            api-field-name="SysBattleId"
            :form="searchFields"
            :external-errors="{}"
            label="Battle ID"
            filter-mode
            class-component="min-w-[120px]"
            slim
            @change="clearNextPage"
          />
        </div>
        <div>
          <crud-field-select
            api-field-name="searchFile"
            :form="searchFields"
            :external-errors="{}"
            filter-mode
            :clearable="false"
            slim
            class-component="min-w-[120px]"
            :options="searchFiles"
            @change="clearNextPage"
          />
        </div>
        <div>
          <crud-field-slot>
            <crud-field-checkbox
              api-field-name="allFiles"
              :form="searchFields"
              :external-errors="{}"
              slim
              filter-mode
              class-component="min-w-[130px]"
              @change="clearNextPage"
            />
          </crud-field-slot>
        </div>
      </div>
    </template>
    <template #table="{ data, loading, height }">
      <div
        v-if="data?.rows?.length"
        class="px-4"
        :class="{ 'opacity-30': loading }"
      >
        <el-table
          :height="height"
          :data="data.rows"
          table-layout="fixed"
          class="gs-scaled"
          header-cell-class-name="gs-font-scaled bg-sky-50 gs-col-padding-mini text-sky-800"
          cell-class-name="gs-col-padding-mini"
        >
          <el-table-column
            v-if="!searchFields.hideColumns.includes('date')"
            prop="Date"
            label="Date"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('pid')"
            prop="PID"
            label="Player"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('action')"
            prop="Action"
            label="Action"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('xp')"
            prop="DeltaXP"
            label="DeltaXP"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('energy')"
            prop="DeltaEnergy"
            label="DeltaEnergy"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('shields')"
            prop="DeltaShields"
            label="DeltaShields"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('gems')"
            prop="DeltaGems"
            label="DeltaGems"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('season_pass_points')"
            prop="DeltaSeasonPassPoints"
            label="DeltaSeasonPassPoints"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('card_points')"
            prop="DeltaCardPoints"
            label="DeltaCardPoints"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('duplicate_points')"
            prop="DeltaDuplicatePoints"
            label="DeltaDuplicatePoints"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('gcoins')"
            prop="DeltaGCoins"
            label="DeltaGCoins"
            :width="200"
          />
          <el-table-column
            v-if="!searchFields.hideColumns.includes('skins')"
            prop="DeltaSkins"
            label="DeltaSkins"
            :width="800"
          />
          <el-table-column
            v-if="searchFields.showResponseModel"
            label="Details"
            :width="700"
          >
            <template #default="{ row }">
              <el-scrollbar max-height="100">
                <template v-if="searchFields.prettyPrint && row.Action.includes('logged')">
                  <pre>{{ JSON.stringify(JSON.parse(row.Details === '' ? '{}' : row.Details), null, 2) }}</pre>
                </template>
                <template v-else>
                  {{ row.Details === '' ? '{}' : row.Details }}
                </template>
              </el-scrollbar>
            </template>
          </el-table-column>
          <el-table-column
            v-if="searchFields.showResponseModel"
            label="Response"
            :width="700"
          >
            <template #default="{ row }">
              <el-scrollbar max-height="100">
                <template v-if="searchFields.prettyPrint">
                  <pre>{{ JSON.stringify(JSON.parse(row.Response === '' ? '{}' : row.Response), null, 2) }}</pre>
                </template>
                <template v-else>
                  {{ row.Response === '' ? '{}' : row.Response }}
                </template>
              </el-scrollbar>
            </template>
          </el-table-column>
        </el-table>
        <div class="my-3 sm:hidden">
          <el-button
            v-if="!isOnFirstPage"
            class="btn btn-prev gs-height-related-lg"
            @click="clearNextPage"
          >
            First Page
          </el-button>
          <el-button
            v-if="!isOnFirstPage"
            class="gs-btn-outlined-primary gs-height-related-lg"
            @click="prevPage()"
          >
            Previous Page
          </el-button>
          <el-button
            v-if="typeof data?.next_page !== 'undefined' && data.next_page > -1"
            class="gs-btn-outlined-primary gs-height-related-lg"
            @click="nextPage(data.current_page, data.next_page, data.skip_files)"
          >
            Next page
          </el-button>
        </div>
      </div>
      <div v-else-if="loading">
        loading...
      </div>
      <div v-else-if="!loading && data?.rows !== undefined">
        no data
      </div>
      <div />
    </template>
    <template #bottomBarLeft="{ data }">
      <div>
        <el-button
          v-if="!isOnFirstPage"
          class="btn btn-prev gs-height-related-lg"
          @click="clearNextPage"
        >
          First Page
        </el-button>
        <el-button
          v-if="!isOnFirstPage"
          class="gs-btn-outlined-primary gs-height-related-lg"
          @click="prevPage()"
        >
          Previous Page
        </el-button>
        <el-button
          v-if="typeof data?.next_page !== 'undefined' && data.next_page > -1"
          class="gs-btn-outlined-primary gs-height-related-lg"
          @click="nextPage(data.current_page, data.next_page, data.skip_files)"
        >
          Next page
        </el-button>
      </div>
    </template>
  </crud-table>
</template>
