<script setup>
</script>

<template>
  <crud-details-page
    api="cosmetics/skin"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Quality -->
        <crud-field-number
          api-field-name="Quality"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.SkinType"
          :visible-options="['Stone']"
          required
        />
      </fields-col>
      <fields-col :sm="6">
        <!-- CardNameTag -->
        <crud-field-selector-client-strings
          label="Name Tag"
          api-field-name="CardNameTag"
          required
        />
        <!-- StoneDescription -->
        <crud-field-selector-client-strings
          label="Description"
          api-field-name="StoneDescription"
          required
        />
      </fields-col>
      <fields-col :sm="6">
        <crud-field-select
          api-field-name="StoneType"
          options-enum="enums.StoneType"
          required
        />
        <!-- Quality -->
        <crud-field-number api-field-name="StoneValue" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- AssetIconFemale -->
        <crud-field-selector-files api-field-name="AssetIconFemale" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
