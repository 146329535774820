import LogoSlash from '../../assets/logo_slash.png'
import LogoGang from '../../assets/logo_gang.png'

const DefaultMeta = {
  Title: '', // if empty string ('') will be overwritten by $route.meta.Title or $route.name
  TitlePrefix: '',
  TitleSuffix: import.meta.env.VITE_GAME === 'gang' ? ' : The Gang : Admin Panel' : ' : Slash&Roll : Admin Panel',
  FaviconFile: import.meta.env.VITE_GAME === 'gang' ? '/favicon_gang.png' : '/favicon.png' // name file in public folder,
}

const RouterMeta = {
  ...DefaultMeta,
  PageHeaderTitle: '', // if empty string ('') will be overwritten by DefaultMeta.Title
  DisableLayoutPageHeader: false, // if $route.meta.DisableLayoutPageHeader - disable only that current rote;
  IdParentSubmenu: [], // array unique ids of parent submenus,
  Entity: null
}

const LoginPageSettings = {
  Logo: import.meta.env.VITE_GAME === 'gang' ? LogoGang : LogoSlash,
  bgClass: 'bg-gradient-to-r from-black/75 via-black to-black/75',
  btnClass: 'text-white hover:bg-slate-900 border-slate-100 hover:border-slate-200 shadow-slate-500/50',
  btnIcon: 'akar-icons:google-fill',
  loadEnvs: true
}

const LayoutSettings = {
  pageHeader: {
    bgClass: 'bg-gradient-to-r from-black to-black/60 text-white font-normal',
    btnClass: 'text-white hover:bg-stone-700 active:bg-stone-500'
  },
  menu: {
    bgClass: 'bg-gradient-to-b from-black to-black/70 text-white',
    Logo: import.meta.env.VITE_GAME === 'gang' ? LogoGang : LogoSlash,
    title: import.meta.env.VITE_GAME === 'gang' ? 'The Gang' : 'Slash&Roll',
    titleClass: 'text-transparent bg-gradient-to-r from-stone-400 to-stone-200 font-black ',
    collapsedBtnClass: 'text-white hover:text-stone-300 active:bg-stone-700',
    textColor: '#ffffff',
    textActiveColor: '#FBBF24FF',
    hoverBgColor: 'rgb(64 64 64)',
    submenuBgColor: 'rgb(82 82 82)'
  }
}

export { DefaultMeta, RouterMeta, LoginPageSettings, LayoutSettings }
