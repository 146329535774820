<template>
  <crud-details-page
    api="rewards/boss-event-global-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <fields-col
        v-for="i in 10"
        :key="i"
      >
        <section-divider>Reward {{ i }}</section-divider>
        <fields-col :sm="6">
          <crud-field-number
            :api-field-name="`PlacesStart${i}`"
            label="PlacesStart"
          />
          <crud-field-number
            :api-field-name="`PlacesEnd${i}`"
            label="PlacesEnd"
          />
        </fields-col>
        <fields-col :sm="6">
          <!-- XpPct -->
          <crud-field-number
            :api-field-name="`XpPct${i}`"
            label="XpPct"
          />
          <!-- Energy -->
          <crud-field-number
            :api-field-name="`Energy${i}`"
            label="Energy"
          />
        </fields-col>
        <fields-col :sm="6">
          <!-- Gems -->
          <crud-field-number
            :api-field-name="`Gems${i}`"
            label="Gems"
          />
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Skin${i * 3 - 2}`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Skin${i * 3 - 2}Amount`"
              label="Skin amount"
            />
          </fields-col>
        </fields-col>
        <fields-col :sm="6">
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Skin${i * 3 - 1}`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Skin${i * 3 - 1}Amount`"
              label="Skin amount"
            />
          </fields-col>
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Skin${i * 3}`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Skin${i * 3}Amount`"
              label="Skin amount"
            />
          </fields-col>
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
