<script setup>
import { ref } from 'vue'
import EnvSelector from '@/components/proxy/EnvSelector.vue'

const active = ref(['0'])
const groupsLength = ref(0)
const intervals = ref(0)
const intervalSize = 100
function renderLoadedData(response) {
  groupsLength.value = response?.groups?.length || 0
  intervals.value = Math.ceil(groupsLength.value / intervalSize)
  return response
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/seasonptsgroups/'
    }"
    :top-actions="{
      excelExport: false,
      importExcel: false,
      addNew: false
    }"
    disable-pagination
    hide-columns-selector
    disable-multi-select-rows
    hide-clear-btn
    :render-loaded-data="renderLoadedData"
  >
    <template #topLeft="{ searchFields }">
      <div class="w-20">
        <EnvSelector v-model:value-field="searchFields.env" />
      </div>
    </template>
    <template #table="{ data, loading }">
      <div
        v-if="data?.groups?.length"
        class="px-1 pt-10 sm:px-4"
        :class="{ 'opacity-30': loading }"
      >
        <el-collapse
          accordion
          class="gs-inverted"
        >
          <el-collapse-item
            v-for="interval in intervals"
            :key="interval"
            :name="`${interval}`"
          >
            <template #title>
              <h1 class="pb-1.5 text-center text-xl text-neutral-500">
                Groups {{ interval * intervalSize - intervalSize + 1 }} - {{ interval * intervalSize }}
              </h1>
            </template>
            <el-collapse
              v-for="(group, index) in data.groups.slice(interval * intervalSize - intervalSize, interval * intervalSize)"
              :key="`${interval}_${index}`"
              v-model="active"
              class="gs-inverted sm-ml-4 ml-2"
            >
              <el-collapse-item :name="`${interval}_${index}`">
                <template #title>
                  <h1 class="pb-1.5 text-center text-xl font-bold">
                    Group {{ interval * intervalSize - intervalSize + index + 1 }}
                  </h1>
                </template>
                <filtering-table
                  v-if="active.includes(`${interval}_${index}`)"
                  :rows="group.players"
                  :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
                  header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                  cell-class-name="gs-col-padding-mini"
                  fit
                  :columns="{
                    id: { header: 'ID', sortable: true, filterField: true, type: 'uint', elAttr: { width: 100 } },
                    name: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 250 } },
                    guild: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 160 } },
                    level: { sortable: true, filterField: true, type: 'int', elAttr: { width: 100 } },
                    points: { sortable: true, filterField: true, type: 'int', elAttr: { width: 100 } }
                  }"
                  index-rows-column
                  :page-sizes="[50, 100, 200]"
                  hide-refresh-btn
                />
              </el-collapse-item>
            </el-collapse>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div v-else-if="loading">
        loading...
      </div>
      <div v-else-if="!loading && data?.rows !== undefined">
        no data
      </div>
      <div />
    </template>
  </crud-table>
</template>
