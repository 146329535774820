<template>
  <crud-details-page
    api="settings/game-setting"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Key -->
        <crud-field-text
          api-field-name="Key"
          required
        />
        <!-- Value -->
        <crud-field-text
          api-field-name="Value"
          required
        />
      </fields-col>
      <fields-col :sm="16">
        <!-- Description -->
        <crud-field-textarea
          api-field-name="Description"
          :textarea-rows="4.5"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
