<script setup>
function renderLoadedData(data) {
  return { items: data.rows, total: data.rows.length }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/autoinvitelogs/'
    }"
    :static-api-params="{
      env: 'prod'
    }"
    :action-col-settings="false"
    :top-actions="false"
    :api-filtering="false"
    :api-pagination="false"
    :api-sorting="false"
    hide-columns-selector
    :render-loaded-data="renderLoadedData"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800'
    }"
    :columns="{
      invitation_id: 'uint:sort',
      player: 'string:sort',
      to_guild: 'string:sort',
      date: 'string:sort',
      updated_at: 'string:sort',
      is_auto: 'bool:sort',
      status: 'string:sort'
    }"
    :columns-settings="{
      ID: false,
      invitation_id: { header: 'Invitation ID', width: 100 },
      player: { elAttr: { minWidth: 200 } },
      to_guild: { header: 'To guild', elAttr: { minWidth: 200 } },
      date: { elAttr: { minWidth: 150 } },
      updated_at: { header: 'Updated at', elAttr: { minWidth: 150 } },
      status: { elAttr: { minWidth: 100 } }
    }"
    disable-multi-select-rows
  />
</template>
