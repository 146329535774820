<script>
import CrudFieldSelectorPveChallenge from '@/components/forms/crud-fields/CrudFieldSelectorPveChallenge.vue'

export default {
  components: {
    CrudFieldSelectorPveChallenge
  }
}
</script>

<template>
  <crud-details-page
    api="pve-challenge-event"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- DurationHours -->
        <crud-field-number api-field-name="DurationHours" />
        <!-- LimitPerSeason -->
        <crud-field-number
          api-field-name="LimitPerSeason"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-files api-field-name="AssetHomeBanner" />
        <crud-field-selector-files api-field-name="AssetHomeBattle" />
        <crud-field-selector-files api-field-name="AssetRewardsPopupHeader" />
        <crud-field-selector-files api-field-name="FakeAsset4" />
      </fields-col>
      <section-divider>Modes</section-divider>
      <fields-col :sm="8">
        <template
          v-for="i in 3"
          :key="i"
        >
          <CrudFieldSelectorPveChallenge
            :api-field-name="`Mode${i}`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
