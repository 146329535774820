<script>
import { ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'EnvSelector',
  props: {
    typeValueField: {
      type: undefined,
      default: undefined
    }
  },
  setup() {
    const options = ref([])
    const order = {
      local: 1,
      dev: 2,
      beta: 3,
      timeshift: 4,
      prod: 5
    }
    Object.entries(app.$store.getters['auth/envs']).forEach(([env, url]) => {
      if (url) {
        options.value.push({ value: env, label: env })
      }
    })
    options.value.sort((a, b) => order?.[a.value] - order?.[b.value])
    const defaultEnv = app.$utils.isDevMode() ? 'local' : options.value.slice(-1)[0]?.value
    return {
      options,
      defaultEnv
    }
  }
}
</script>

<template>
  <crud-type-fields
    v-bind="$props"
    type-value-field="select"
    full-width
    :options="options"
    :init-value="defaultEnv"
    label="Environment"
    :clearable="false"
  />
</template>

<style scoped></style>
