<script setup>
import { ref } from 'vue'

const sliderSize = ref(155)
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/avatarslist/'
    }"
    :top-actions="false"
    :action-col-settings="false"
    :static-api-params="{
      env: 'prod'
    }"
    hide-columns-selector
  >
    <template #table="{ data, loading }">
      <div
        v-if="data?.rows?.length"
        class="p-8"
        :class="{ 'opacity-30': loading }"
      >
        <div class="inline-flex justify-start flex-wrap flex-row gap-2">
          <div
            v-for="item in data?.rows"
            :key="item?.player"
            class="cursor-pointer hover:scale-150 transition-all ease-in hover:z-10 relative"
          >
            <router-link
              :to="
                $utils.bindStaticParams({
                  name: 'gameplay-players-details',
                  params: { id: item?.player },
                  query: { env: 'prod' }
                })
              "
              target="_blank"
            >
              <el-image
                :src="item?.avatar || ''"
                lazy
                alt="avatar"
                :style="`width: ${sliderSize}px; height: ${sliderSize}px`"
              />
            </router-link>
          </div>
        </div>
      </div>
      <div v-else-if="loading">
        loading...
      </div>
      <div v-else-if="!loading && data?.rows !== undefined">
        no data
      </div>
      <div />
    </template>
    <template #bottomBarLeft>
      <div class="flex flex-row gap-4 items-center">
        <div class="text-sm whitespace-nowrap">
          Size px:
        </div>
        <el-slider
          v-model="sliderSize"
          :min="100"
          :max="300"
          class="w-32"
          size="small"
        />
      </div>
    </template>
  </crud-table>
</template>
