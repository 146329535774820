<script setup>
</script>

<template>
  <crud-details-page
    api="season-pass"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- SeasonNo -->
        <crud-field-number
          api-field-name="SeasonNo"
          required
        />
      </fields-col>
      <fields-col :sm="4">
        <!-- No -->
        <crud-field-number
          api-field-name="No"
          required
        />
        <!-- IsFinalReward -->
        <crud-field-switcher
          api-field-name="IsFinalReward"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- RequiredPoints -->
        <crud-field-number
          api-field-name="RequiredPoints"
          required
        />
        <!-- RepeatPoints -->
        <crud-field-number
          api-field-name="RepeatPoints"
        />
      </fields-col>
      <section-divider>Normal rewards</section-divider>
      <fields-col :sm="8">
        <!-- NormalGems -->
        <crud-field-number api-field-name="NormalGems" />
        <!-- NormalEnergy -->
        <crud-field-number api-field-name="NormalEnergy" />
        <!-- NormalDuplicatePoints -->
        <crud-field-number api-field-name="NormalDuplicatePoints" />
      </fields-col>
      <fields-col :sm="4">
        <!-- NormalXpPct -->
        <crud-field-number api-field-name="NormalXpPct" />
        <!-- NormalScore -->
        <crud-field-number
          api-field-name="NormalScore"
          label="Normal score (attack points)"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- NormalSkin -->
        <crud-field-selector-skin api-field-name="NormalSkin" />
        <crud-field-number api-field-name="NormalSkinAmount" />
      </fields-col>
      <section-divider>Premium rewards</section-divider>
      <fields-col :sm="8">
        <!-- PremiumGems -->
        <crud-field-number api-field-name="PremiumGems" />
        <!-- PremiumEnergy -->
        <crud-field-number api-field-name="PremiumEnergy" />
        <!-- PremiumDuplicatePoints -->
        <crud-field-number api-field-name="PremiumDuplicatePoints" />
      </fields-col>
      <fields-col :sm="4">
        <!-- PremiumXpPct -->
        <crud-field-number api-field-name="PremiumXpPct" />
        <!-- PremiumScore -->
        <crud-field-number
          api-field-name="PremiumScore"
          label="Premium score (attack points)"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- PremiumSkin -->
        <crud-field-selector-skin api-field-name="PremiumSkin" />
        <crud-field-number api-field-name="PremiumSkinAmount" />
        <!-- PremiumIsSpecial -->
        <crud-field-switcher
          label="Highlight premium reward on the banner"
          api-field-name="PremiumIsSpecial"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
