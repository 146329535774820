<template>
  <crud-details-page
    api="rewards/card-event-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <fields-col
        v-for="i in 10"
        :key="i"
      >
        <section-divider>Milestone {{ i }}</section-divider>
        <fields-col :sm="8">
          <crud-field-number
            :api-field-name="`Threshold${i}`"
            label="Threshold"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- XpPct -->
          <crud-field-number
            :api-field-name="`XpPct${i}`"
            label="XpPct"
          />
          <!-- Energy -->
          <crud-field-number
            :api-field-name="`Energy${i}`"
            label="Energy"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- Gems -->
          <crud-field-number
            :api-field-name="`Gems${i}`"
            label="Gems"
          />
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Skin${i}`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Skin${i}Amount`"
              label="Skin amount"
            />
          </fields-col>
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
