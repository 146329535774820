<script>
import CrudFieldSelectorLootbox from '@/components/forms/crud-fields/CrudFieldSelectorLootbox.vue'
import CrudFieldSelectorClientStrings from '!/components/forms/crud-fields/CrudFieldSelectorClientStrings.vue'

export default {
  components: { CrudFieldSelectorLootbox, CrudFieldSelectorClientStrings }
}
</script>

<template>
  <crud-details-page
    api="chests/duplicate-shop"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- SeasonNo -->
        <crud-field-number
          :sm="10"
          api-field-name="SeasonNo"
        />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-number
          v-for="i in 3"
          :key="i"
          :api-field-name="`SeasonDayReset${i}`"
        />
      </fields-col>
      <section-divider>Limited Lootboxes</section-divider>
      <CrudFieldSelectorLootbox
        v-for="i in 3"
        :key="i"
        :sm="7"
        :api-field-name="`LimitedLootbox${i}`"
      />
      <section-divider>Lootboxes</section-divider>
      <fields-col :sm="14">
        <template
          v-for="i in 6"
          :key="i"
        >
          <CrudFieldSelectorLootbox
            :sm="12"
            :api-field-name="`Lootbox${i}`"
          />
          <CrudFieldSelectorClientStrings
            :sm="12"
            :api-field-name="`Lootbox${i}NameTag`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
