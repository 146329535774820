<template>
  <crud-details-page
    api="settings/guild-search-option"
  >
    <template #form>
      <fields-col :sm="8">
        <crud-field-text
          api-field-name="Label"
        />
        <crud-field-text
          api-field-name="SortBy"
          label="SortBy (leave empty or type 'players')"
        />
        <crud-field-number api-field-name="GuildFirstRecommendedSearchJoinablePlayersLimit" />
        <crud-field-number api-field-name="GuildRecommendedSearchJoinablePlayersLimit" />
        <crud-field-number api-field-name="GuildRecommendedSearchResultsLimit" />
        <crud-field-number api-field-name="GuildSearchResultsLimit" />
        <crud-field-number
          api-field-name="Segment1CountryPct"
          :precision="2"
          label="Segment1 Country"
          slim
        />
        <crud-field-number
          api-field-name="Segment2IntPct"
          :precision="2"
          label="Segment2 INT"
          slim
        />
        <crud-field-number
          api-field-name="Segment3OtherPct"
          :precision="2"
          label="Segment3 Other"
          slim
        />
        <crud-field-number
          api-field-name="ForcePlayerTier"
          label="ForcePlayerTier (0:n/a, 1-4:value)"
        />
        <crud-field-text
          api-field-name="ExcludeCountriesCSV"
          label="ExcludeCountries (leave empty or type ie:FR,PL,US)"
        />
        <crud-field-switcher
          api-field-name="UseAlternativeBiScore"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
