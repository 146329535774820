<script setup></script>

<template>
  <crud-details-page
    api="cosmetics/forge-receipt"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.SkinType"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-number api-field-name="SeasonNo" />
        <!-- ForgeNameTag -->
        <crud-field-selector-client-strings api-field-name="ForgeNameTag" />
      </fields-col>
      <!-- SeasonNo -->
      <fields-col :sm="8">
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- AssetBackground -->
        <crud-field-selector-files api-field-name="AssetBackground" />
      </fields-col>
      <template
        v-for="i in 5"
        :key="i"
      >
        <section-divider>Tier {{ i }}</section-divider>
        <fields-col :sm="6">
          <crud-field-selector-skin
            :api-field-name="`Tier${i}Skin${i > 1 && i < 5 ? '1' : ''}`"
            label="Skin 1"
          />
          <crud-field-selector-skin
            v-if="i > 1 && i < 5"
            :api-field-name="`Tier${i}Skin${i > 1 && i < 5 ? '2' : ''}`"
            label="Skin 2"
          />
        </fields-col>
        <fields-col :sm="6">
          <crud-field-selector-client-strings
            :api-field-name="`Tier${i}Skin${i > 1 && i < 5 ? '1' : ''}NameTag`"
            label="Name tag 1"
          />
          <crud-field-selector-client-strings
            v-if="i > 1 && i < 5"
            :api-field-name="`Tier${i}Skin2NameTag`"
            label="Name tag 2"
          />
        </fields-col>
        <fields-col
          v-if="i > 1"
          :sm="12"
        >
          <crud-field-selector-skin
            :sm="16"
            :api-field-name="`Tier${i}Currency1`"
            label="Currency 1"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Tier${i}CurrencyAmount1`"
            label="Amount 1"
          />
          <crud-field-selector-skin
            :sm="16"
            :api-field-name="`Tier${i}Currency2`"
            label="Currency 2"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Tier${i}CurrencyAmount2`"
            label="Amount 2"
          />
          <crud-field-selector-skin
            v-if="i > 1 && i < 5"
            :sm="16"
            :api-field-name="`Tier${i}Currency3`"
            label="Currency 3"
          />
          <crud-field-number
            v-if="i > 1 && i < 5"
            :sm="8"
            :api-field-name="`Tier${i}CurrencyAmount3`"
            label="Amount 3"
          />
          <crud-field-selector-skin
            v-if="i > 1 && i < 5"
            :sm="16"
            :api-field-name="`Tier${i}Currency4`"
            label="Currency 4"
          />
          <crud-field-number
            v-if="i > 1 && i < 5"
            :sm="8"
            :api-field-name="`Tier${i}CurrencyAmount4`"
            label="Amount 4"
          />
        </fields-col>
      </template>
    </template>
  </crud-details-page>
</template>
