<script>
import CrudFieldSelectorHitThreeReward from '@/components/forms/crud-fields/CrudFieldSelectorHitThreeEventReward.vue'
import CrudFieldSelectorCustomDice from '@/components/forms/crud-fields/CrudFieldSelectorCustomDice.vue'
import CrudFieldSelectorHitThreeEventGlobalReward
  from '@/components/forms/crud-fields/CrudFieldSelectorHitThreeEventGlobalReward.vue'

export default {
  components: {
    CrudFieldSelectorHitThreeReward,
    CrudFieldSelectorCustomDice,
    CrudFieldSelectorHitThreeEventGlobalReward
  }
}
</script>

<template>
  <crud-details-page
    api="hit-three-event"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- DurationHours -->
        <crud-field-number
          api-field-name="DurationHours"
          required
        />
        <!-- DurationMinutes -->
        <crud-field-number
          api-field-name="DurationMinutes"
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.MoveType"
          required
        />
      </fields-col>
      <fields-col :sm="6">
        <!-- HitOnePoints -->
        <crud-field-number api-field-name="HitOnePoints" />
        <!-- HitTwoPoints -->
        <crud-field-number api-field-name="HitTwoPoints" />
        <!-- HitThreePoints -->
        <crud-field-number api-field-name="HitThreePoints" />
      </fields-col>
      <fields-col :sm="6">
        <!-- GlobalReward -->
        <CrudFieldSelectorHitThreeEventGlobalReward api-field-name="GlobalReward" />
        <!-- Reward -->
        <CrudFieldSelectorHitThreeReward api-field-name="Reward" />
        <!-- Color -->
        <crud-field-text api-field-name="Color" />
        <!-- CustomDice -->
        <CrudFieldSelectorCustomDice api-field-name="CustomDice" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetButton -->
        <crud-field-selector-files api-field-name="AssetButton" />
        <!-- AssetBanner -->
        <crud-field-selector-files api-field-name="AssetBanner" />
        <!-- AssetCurrency -->
        <crud-field-selector-files api-field-name="AssetCurrency" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
