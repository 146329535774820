<script>
import { defineAsyncComponent } from 'vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'

export default {
  name: 'HitThreeEventRewardSelector',
  components: {
    DynamicSelector,
    HitThreeEventRewardDetailsPage: defineAsyncComponent(() => import('@/pages/rewards/hit-three-event-rewards/DetailsPage.vue'))
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <DynamicSelector
    :label="filterMode ? '' : 'Hit Three Event Reward'"
    show-img-preview-column
    :columns-settings="{
      Label: {}
    }"
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    api="rewards/hit-three-event-rewards"
    :edit-model-route-name="filterMode ? '' : 'hit-three-event-rewards-details'"
  >
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector }">
      <HitThreeEventRewardDetailsPage
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
  </DynamicSelector>
</template>
