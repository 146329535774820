<template>
  <crud-details-page
    api="settings/incubatormessage"
  >
    <template #form>
      <fields-col :sm="8">
        <crud-field-number api-field-name="BattleNo" />
        <crud-field-number api-field-name="SendTime" />
        <crud-field-switcher api-field-name="HasNotification" />
      </fields-col>
      <!--      <section-divider /> -->
      <fields-col :sm="24">
        <crud-field-selector-client-strings api-field-name="Message" />
      </fields-col>
      <fields-col :sm="24">
        <crud-field-text
          api-field-name="Str"
          label="Message (do testów tymczowy text, potem będzie użyty client string)"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
