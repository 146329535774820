<script>
import CrudFieldSelectorChest from '@/components/forms/crud-fields/CrudFieldSelectorChest.vue'

export default {
  components: { CrudFieldSelectorChest }
}
</script>

<template>
  <crud-details-page
    api="cosmetics/skin"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Quality -->
        <crud-field-number api-field-name="Quality" />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.SkinType"
          :visible-options="['GroupOfSkins']"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- AssetIconFemale -->
        <crud-field-selector-files api-field-name="AssetIconFemale" />
        <!-- DropsFrom -->
        <CrudFieldSelectorChest api-field-name="DropsFrom" />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-skin
          v-for="i in 4"
          :key="i"
          :api-field-name="`Skin${i}`"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
