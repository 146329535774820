<script setup></script>

<template>
  <crud-details-page
    api="division-settings/battle-reward"
  >
    <template #form>
      <!-- DivisionNo -->
      <crud-field-number
        :sm="8"
        api-field-name="DivisionNo"
        required
      />
      <fields-col :sm="8">
        <!-- XpPct -->
        <crud-field-number api-field-name="XpPct" />
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
      </fields-col>
      <!-- SeasonPassPoints -->
      <crud-field-number
        :sm="8"
        api-field-name="SeasonPassPoints"
      />
    </template>
  </crud-details-page>
</template>
