<script setup></script>

<template>
  <crud-details-page
    api="rewards/facebook-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="5">
        <!-- No -->
        <crud-field-number api-field-name="No" />
        <!-- RequiredFriendsCount -->
        <crud-field-number
          api-field-name="RequiredFriendsCount"
          required
        />
        <!-- IsForFacebookConnect -->
        <crud-field-switcher
          api-field-name="IsForFacebookConnect"
          required
        />
      </fields-col>
      <fields-col :sm="5">
        <!-- XpPct -->
        <crud-field-number api-field-name="XpPct" />
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
      </fields-col>
      <fields-col :sm="14">
        <template
          v-for="i in 2"
          :key="i"
        >
          <crud-field-selector-skin
            :sm="16"
            :api-field-name="`Skin${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Skin${i}Amount`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
