<script setup>
import { ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

function initItem(form) {
  form.simChestIncomeCount = 100
  form.simChestIncomeSimulations = 25
  return form
}
const loading = ref({})
function simChest(form) {
  loading.value.simChest = true
  app.$axios
    .get(`/admin/api/proxy/request/`, {
      params: {
        data: [],
        env: 'dev',
        chest_id: form.Versioned.Root,
        count: form.simChestIncomeCount,
        simulations: form.simChestIncomeSimulations,
        uri: '/proxy/simchest/',
        method: 'GET'
      }
    })
    .then(({ data }) => {
      app.$messageBox
        .confirm(data.logs, undefined, {
          confirmButtonText: 'OK',
          type: 'info',
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          icon: 'none',
          customClass: 'md:max-w-screen-md'
        })
        .catch(() => {})
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value.simChest = false
    })
}

function simChestIncome(form) {
  loading.value.simChestIncome = true
  app.$axios
    .get(`/admin/api/proxy/request/`, {
      params: {
        data: [],
        env: 'dev',
        chest_id: form.Versioned.Root,
        count: form.simChestIncomeCount,
        simulations: form.simChestIncomeSimulations,
        uri: '/proxy/simchestincome/',
        method: 'GET'
      }
    })
    .then(({ data }) => {
      app.$messageBox
        .confirm(data.logs, undefined, {
          confirmButtonText: 'OK',
          type: 'info',
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          icon: 'none',
          customClass: 'md:max-w-screen-md'
        })
        .catch(() => {})
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value.simChestIncome = false
    })
}
</script>

<template>
  <crud-details-page
    api="chests/chest"
    disable-top-margin
    :render-init-item="initItem"
  >
    <template #form="{ form }">
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ChestType"
          :visible-options="['Wheel']"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- IsEventChest -->
        <crud-field-switcher api-field-name="IsEventChest" />
        <!-- WheelDailyReset -->
        <crud-field-switcher api-field-name="WheelDailyReset" />
        <!-- WheelOneTimeRewards -->
        <crud-field-switcher api-field-name="WheelOneTimeRewards" />
      </fields-col>
      <fields-col :sm="4">
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
        <!-- Price1 -->
        <crud-field-text
          api-field-name="AlternatingPrice1"
          label="Price1"
        />
        <!-- Price10 -->
        <crud-field-text
          api-field-name="AlternatingPrice10"
          label="Price10"
        />
        <!-- OpenLimit -->
        <crud-field-number api-field-name="OpenLimit" />
      </fields-col>
      <fields-col :sm="7">
        <!-- AssetShopPreview -->
        <crud-field-selector-files api-field-name="AssetShopPreview" />
        <!-- AssetBackground -->
        <crud-field-selector-files api-field-name="AssetBackground" />
        <!-- AssetHomeIcon -->
        <crud-field-selector-files api-field-name="AssetHomeIcon" />
        <!-- AssetChestPreview -->
        <crud-field-selector-files
          api-field-name="AssetChestPreview"
          label="AssetPopup"
        />
        <!-- AssetSeasonBanner -->
        <crud-field-selector-files
          api-field-name="AssetSeasonBanner"
          label="AssetShopBanner"
        />
      </fields-col>
      <section-divider>Loots</section-divider>
      <el-col>
        <el-card
          shadow="never"
          class="gs-font-scaled relative mt-8 mb-10"
        >
          <fields-col>
            <crud-field-slot
              :sm="4"
              label="Sim Chest"
              empty-label
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChest"
                @click="simChest(form)"
              >
                Sim spin
              </el-button>
            </crud-field-slot>
            <crud-field-slot
              label="Sim Chest Income"
              empty-label
              class="flex-none"
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChestIncome"
                @click="simChestIncome(form)"
              >
                Sim spin income
              </el-button>
            </crud-field-slot>
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeCount"
              label="Number of spins"
            />
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeSimulations"
              label="Number of simulations"
            />
          </fields-col>
          <span class="font-related-xs absolute bottom-2 left-4 text-neutral-500">before sim: save & do CC DEV</span>
        </el-card>
      </el-col>
      <template
        v-for="i in 12"
        :key="i"
      >
        <crud-field-selector-skin
          :sm="10"
          :api-field-name="`Skin${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Quantity${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Weight${i}`"
        />
        <crud-field-select
          :sm="4"
          :api-field-name="`StageQuantities${i}`"
          :options="$utils.getOptionsFromArray(['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'])"
          label="Slot Quality"
        />
        <crud-field-switcher
          :sm="2"
          :api-field-name="`IsPreview${i}`"
          label="Is Jackpot"
        />
      </template>
    </template>
  </crud-details-page>
</template>
