<script setup></script>

<template>
  <crud-details-page
    api="rewards/deep-link"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="5">
        <!-- Code -->
        <crud-field-text
          api-field-name="Code"
          required
        />
        <!-- StartTime -->
        <crud-field-date-time
          api-field-name="StartTime"
          required
        />
        <!-- DurationHours -->
        <crud-field-number
          api-field-name="DurationHours"
          required
        />
      </fields-col>
      <fields-col :sm="5">
        <!-- Amount -->
        <crud-field-number
          api-field-name="Amount"
          label="Energy amount"
        />
        <!-- AvailableFromLevel -->
        <crud-field-number api-field-name="AvailableFromLevel" />
      </fields-col>
      <fields-col :sm="14">
        <template
          v-for="i in 2"
          :key="i"
        >
          <crud-field-selector-skin
            :sm="16"
            :api-field-name="`Skin${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Skin${i}Amount`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
