<script setup>
</script>

<template>
  <crud-details-page
    api="settings/guild-banner-config"
  >
    <template #form>
      <fields-col :sm="8">
        <crud-field-text
          api-field-name="Label"
        />
        <crud-field-selector-files
          api-field-name="Banner"
        />
        <crud-field-text
          api-field-name="GuildIds"
          label="Guild Ids (separated by ',')"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
