<script>
export default {
  name: 'MenuItem',
  data() {
    return {
      loadingCache: {}
    }
  },
  methods: {
    clearCache(env) {
      this.loadingCache[env] = true
      this.$axios
        .get(`/admin/api/proxy/request/`, { params: { method: 'GET', data: [], uri: '/proxy/clearcache/', env } })
        .then(() => {
          this.loadingCache[env] = false
          this.$message({
            message: `${env.toUpperCase()} cache cleared`,
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loadingCache[env] = false
          this.$message({
            message: `${env.toUpperCase()} cache failed`,
            type: 'warning',
            offset: 40
          })
        })
    }
  }
}
</script>

<template>
  <li class="cursor-text text-center">
    <el-button
      type="warning"
      :loading="loadingCache.dev"
      class="gs-size-scaled"
      size="small"
      @click="clearCache('dev')"
    >
      <span v-if="$store.getters['main/pageMenuCollapse']">CCD</span>
      <span v-else>CC DEV</span>
    </el-button>
  </li>
</template>

<style scoped></style>
