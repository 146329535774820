<template>
  <crud-details-page
    api="division-settings/division-level"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- DivisionNo -->
        <crud-field-number
          api-field-name="DivisionNo"
          required
        />
        <!-- MaximumLevel -->
        <crud-field-number
          api-field-name="MaximumLevel"
        />
        <!-- AssetIcon -->
        <crud-field-selector-files
          api-field-name="AssetIcon"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
