<script setup>
</script>

<template>
  <crud-details-page
    api="rewards/chest-rush-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="5">
        <!-- SeasonNo -->
        <crud-field-number api-field-name="SeasonNo" />
        <!-- No -->
        <crud-field-number api-field-name="No" />
        <!-- SeasonPassPoints -->
        <crud-field-number
          api-field-name="SeasonPassPoints"
        />
        <!-- ForNewbies -->
        <crud-field-switcher api-field-name="ForNewbies" />
      </fields-col>
      <fields-col :sm="5">
        <!-- XpPct -->
        <crud-field-number api-field-name="XpPct" />
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
      </fields-col>
      <fields-col :sm="14">
        <template
          v-for="i in 4"
          :key="i"
        >
          <crud-field-selector-skin
            :sm="16"
            :api-field-name="`Skin${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Skin${i}Amount`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
