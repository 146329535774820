<script>
export default {
  data() {
    return {
      loading: {}
    }
  },
  methods: {
    initItem(form) {
      form.simChestLevel = 5
      form.simEventId = 1
      form.simChestGroup = 0
      form.simSeasonNo = 0
      form.simSeasonDay = 1
      form.simChestIncomeCount = 100
      form.simChestIncomeSimulations = 25
      return form
    },
    simChest(form) {
      this.loading.simChest = true
      this.$axios
        .get(`/admin/api/proxy/request/`, {
          params: {
            data: [],
            env: 'dev',
            chest_id: form.Versioned.Root,
            event_id: form.simEventId,
            level: form.simChestLevel,
            group: form.simChestGroup,
            season_no: form.simSeasonNo,
            season_day: form.simSeasonDay,
            count: form.simChestIncomeCount,
            simulations: form.simChestIncomeSimulations,
            uri: '/proxy/simchest/',
            method: 'GET'
          }
        })
        .then(({ data }) => {
          this.$messageBox
            .confirm(data.logs, undefined, {
              confirmButtonText: 'OK',
              type: 'info',
              dangerouslyUseHTMLString: true,
              showCancelButton: false,
              icon: 'none',
              customClass: 'md:max-w-screen-md'
            })
            .catch(() => {})
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.simChest = false
        })
    },
    simChestIncome(form) {
      this.loading.simChestIncome = true
      this.$axios
        .get(`/admin/api/proxy/request/`, {
          params: {
            data: [],
            env: 'dev',
            chest_id: form.Versioned.Root,
            event_id: form.simEventId,
            level: form.simChestLevel,
            group: form.simChestGroup,
            season_no: form.simSeasonNo,
            season_day: form.simSeasonDay,
            count: form.simChestIncomeCount,
            simulations: form.simChestIncomeSimulations,
            uri: '/proxy/simchestincome/',
            method: 'GET'
          }
        })
        .then(({ data }) => {
          this.$messageBox
            .confirm(data.logs, undefined, {
              confirmButtonText: 'OK',
              type: 'info',
              dangerouslyUseHTMLString: true,
              showCancelButton: false,
              icon: 'none',
              customClass: 'md:max-w-screen-md'
            })
            .catch(() => {})
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.simChestIncome = false
        })
    }
  }
}
</script>

<template>
  <crud-details-page
    api="chests/chest"
    disable-top-margin
    :render-init-item="initItem"
  >
    <template #form="{ form }">
      <fields-col :sm="7">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ChestType"
          :visible-options="['MysteryChest']"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings api-field-name="NameTag" />
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
        <!-- Key -->
        <crud-field-selector-skin
          api-field-name="ChestKey"
          label="Key"
          :init-filters="{ Type: 'ChestKey' }"
        />
      </fields-col>
      <fields-col :sm="5">
        <!-- PriceSpecial -->
        <crud-field-number api-field-name="PriceSpecial" />
        <!-- MysterySkinType -->
        <crud-field-select
          api-field-name="MysterySkinType"
          options-enum="enums.SkinType"
        />
        <!-- MysterySkinQuality -->
        <crud-field-number api-field-name="MysterySkinQuality" />
        <!-- NoItemDuplicates -->
        <crud-field-switcher api-field-name="NoItemDuplicates" />
        <!-- IsAutoOpen -->
        <crud-field-switcher api-field-name="IsAutoOpen" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetShopPreview -->
        <crud-field-selector-files api-field-name="AssetShopPreview" />
        <!-- AssetChestAnimation -->
        <crud-field-selector-files api-field-name="AssetChestAnimation" />
        <!-- AssetBackground -->
        <crud-field-selector-files api-field-name="AssetBackground" />
        <!-- AssetBackground2 -->
        <crud-field-selector-files api-field-name="AssetBackground2" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetSeasonPreview -->
        <crud-field-selector-files api-field-name="AssetSeasonPreview" />
        <!-- AssetChestPreview -->
        <crud-field-selector-files api-field-name="AssetChestPreview" />
        <!-- AssetSeasonBanner -->
        <crud-field-selector-files api-field-name="AssetSeasonBanner" />
      </fields-col>
      <section-divider>Loot settings</section-divider>
      <fields-col :sm="8">
        <!-- DuplicatedDrawDuplicatePointsCommon -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsCommon" />
        <!-- DuplicatedDrawDuplicatePointsUncommon -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsUncommon" />
        <!-- DuplicatedDrawDuplicatePointsRare -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsRare" />
        <!-- DuplicatedDrawDuplicatePointsEpic -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsEpic" />
        <!-- DuplicatedDrawDuplicatePointsLegendary -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsLegendary" />
      </fields-col>
      <section-divider>Loots</section-divider>
      <el-col :sm="24">
        <el-card
          shadow="never"
          class="gs-font-scaled relative mt-6 mb-10"
        >
          <fields-col>
            <crud-field-slot
              label="Sim Pack"
              empty-label
              class="flex-none"
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChest"
                @click="simChest(form)"
              >
                Sim pack
              </el-button>
            </crud-field-slot>
            <crud-field-slot
              label="Sim Chest Income"
              empty-label
              class="flex-none"
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChestIncome"
                @click="simChestIncome(form)"
              >
                Sim pack income
              </el-button>
            </crud-field-slot>
            <crud-field-number
              :sm="4"
              api-field-name="simSeasonNo"
              :max="100"
              label="Sim season no"
            />
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeCount"
              label="Number of chests"
            />
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeSimulations"
              label="Number of simulations"
            />
          </fields-col>
          <span class="font-related-xs absolute bottom-2 left-4 text-neutral-500">before sim: save & do CC DEV</span>
        </el-card>
      </el-col>
    </template>
  </crud-details-page>
</template>
