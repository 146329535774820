<script>
import EnvSelector from '@/components/proxy/EnvSelector.vue'

export default {
  components: { EnvSelector },
  data() {
    return {
      isBotsModel: false,
      referToData: []
    }
  },
  computed: {
    guildListData() {
      return this.isBotsModel ? this.referToData.rows.filter(row => row.fill_with_bots) : this.referToData.rows
    }
  },
  methods: {
    dynamicRowClass({ rowIndex }) {
      return Math.floor(rowIndex / 16) % 2 === 1 ? 'bg-stone-50' : 'bg-indigo-50'
    },
    renderLoadedData(response) {
      this.referToData = response
      this.referToData.rows = this.referToData?.rows || []
      return { items: response.rows, total: response.rows.length }
    }
  }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/guildlist/'
    }"
    :top-actions="false"
    :action-col-settings="false"
    :render-loaded-data="renderLoadedData"
    hide-columns-selector
    disable-multi-select-rows
    :columns-settings="{
      ID: {
        elAttr: {
          formatter: (row) => row.id
        },
        filterField: true
      },
      guild: { width: 200, header: 'Name', filterField: true, elAttr: { formatter: (row) => row?.guild?.name } },
      created: { header: 'Created at', width: 150 },
      zone: { width: 80 },
      country: { width: 75 },
      open: { width: 75 },
      division_no: { header: 'Division', width: 95 },
      req_level: { header: 'ReqLvl', width: 75 },
      power: { width: 70 },
      players: { width: 75 },
      searchable: { width: 95 },
      activity: { header: 'SkirmishActivity%', width: 130 },
      activity_push: { header: 'ActivityPush', width: 110 },
      description: { width: 800 },
      rev_30d: { header: '$Rev30d', width: 80 },
      rev_30d_by: { header: 'Revenue30d by', width: 150 },
      bi_score_new: { header: 'BiScoreNew', width: 110 },
      bi_score_old: { header: 'BiScoreOld', width: 110 }
    }"
    :columns="{
      guild: 'string:sort',
      created: 'string:sort',
      zone: 'string:sort',
      country: 'string:sort',
      open: 'bool:sort',
      division_no: 'int:sort',
      req_level: 'int:sort',
      power: 'int:sort',
      players: 'int:sort',
      rev_30d: 'int:sort',
      rev_30d_by: 'string:sort',
      bi_score_new: 'float:sort',
      bi_score_old: 'float:sort',
      searchable: 'string:sort',
      activity: 'int:sort',
      activity_push: 'int:sort',
      description: 'string:sort'
    }"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800',
      'row-class-name': dynamicRowClass
    }"
    :enable-cell-edit-dialogs="false"
    :api-pagination="false"
    :api-filtering="false"
    :api-sorting="false"
    :rows="guildListData"
    @reset-all="isBotsModel = false"
  >
    <template #topLeft="{ searchFields }">
      <div class="flex align-middle">
        <div class="w-20">
          <EnvSelector v-model:value-field="searchFields.env" />
        </div>
        <div>
          <crud-type-fields
            label="Bots"
            class="ml-2"
          >
            <el-switch
              v-model="isBotsModel"
              inline-prompt
              active-text="&nbsp;&nbsp;FilledWithBots&nbsp;&nbsp;"
              inactive-text="&nbsp;&nbsp;All&nbsp;&nbsp;"
              inactive-color="#13ce66"
              active-color="rgb(67 56 202)"
              class="-translate-y-1"
            />
          </crud-type-fields>
        </div>
      </div>
    </template>
    <template #cell_guild="{ row, searchFields }">
      <router-link
        :to="{ name: 'gameplay-guild-list-details', params: { id: row.id }, query: { env: searchFields.env } }"
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
      >
        {{ row.guild.name }}
      </router-link>
    </template>
    <template #cell_players="{ row }">
      <span :class="{ 'font-bold text-cyan-600': row.players > 18 }">{{ row.players }}</span>
    </template>
  </crud-table>
</template>
