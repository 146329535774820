<script>
import BattleScorePreview from '@/components/shared/BattleScorePreview.vue'

export default {
  components: { BattleScorePreview },
  setup() {
    return {
      previewActions: {}
    }
  },
  methods: {
    renderLoadedData(response) {
      return { items: response.rows, total: response.rows.length }
    }
  }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/pvechallenges/'
    }"
    :action-col-settings="false"
    :top-actions="false"
    :static-api-params="{
    }"
    :columns="{
      guild: 'string:sort',
      created_date: 'string:sort',
      end_date: 'string:sort',
      pve_challenge: 'int:sort',
      difficulty: 'string:sort',
      pve_challenge_event: 'int:sort',
      current_line: 'int:sort',
      revenue: 'float:sort'
    }"
    :columns-settings="{
      ID: {
        elAttr: {
          formatter: (row) => row.id
        },
        filterField: true
      },
      guild: { width: 200, filterField: true },
      created_date: { width: 150, header: 'Created' },
      end_date: { width: 150, header: 'End' },
      pve_challenge: { width: 120, header: 'Pve Challenge' },
      difficulty: { width: 100 },
      pve_challenge_event: { width: 150, header: 'Pve Challenge Event' },
      current_line: { width: 100, header: 'Current Line' },
      revenue: { width: 100 }
    }"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800'
    }"
    enable-number-rows
    hide-columns-selector
    :api-pagination="false"
    :api-filtering="false"
    :api-sorting="false"
    :enable-cell-edit-dialogs="false"
    :render-loaded-data="renderLoadedData"
    disable-multi-select-rows
  >
    <template #topLeft="{ searchFields }">
      <div class="flex align-middle">
        <div class="w-20">
          <env-selector v-model:value-field="searchFields.env" />
        </div>
        <div class="ml-2 w-28">
          <crud-type-fields
            v-model:value-field="searchFields.season_no"
            :min="0"
            :init-value="0"
            type-value-field="int"
            label="Season No"
          />
        </div>
      </div>
    </template>
    <template #cell_ID="{ row }">
      <a
        v-if="row?.id"
        class="block cursor-pointer text-blue-500 underline underline-offset-4 hover:text-blue-600"
        @click="previewActions?.previewBattle?.({ battle_id: row.id })"
      >{{ row.id }}</a>
    </template>
    <template #cell_guild="{ row, searchFields }">
      <router-link
        :to="{ name: 'gameplay-guild-list-details', params: { id: row.guild.id }, query: { env: searchFields.env } }"
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
      >
        {{ row.guild.name }}
      </router-link>
    </template>
    <template #drawers>
      <BattleScorePreview @bind-preview-battle-action="previewActions.previewBattle = $event" />
    </template>
  </crud-table>
</template>
