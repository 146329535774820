<script>
import { defineAsyncComponent } from 'vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'

export default {
  name: 'SkinSelector',
  components: {
    DynamicSelector,
    SkinDetailsPage: defineAsyncComponent(() => import('@/pages/cosmetics/skins/DetailsPage.vue')),
    CardDetailsPage: defineAsyncComponent(() => import('@/pages/cosmetics/cards/DetailsPage.vue'))
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isCard: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <DynamicSelector
    :label="filterMode ? '' : 'Skin'"
    :width="720"
    show-img-preview-column
    :columns-settings="{
      Label: {},
      Type: { width: 130 },
      Quality: { width: 90 },
      IsNotUsed: { width: 66, cellEdit: false, filterField: false }
    }"
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    api="cosmetics/skins"
    :edit-model-route-name="filterMode ? '' : 'skins-details'"
  >
    <template #top="{ filters }">
      <el-switch
        v-model="filters.NotUsed"
        inline-prompt
        active-text="Not used"
        inactive-text="Not used"
      />
    </template>
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector }">
      <SkinDetailsPage
        v-if="!isCard"
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
      <CardDetailsPage
        v-else
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
  </DynamicSelector>
</template>
