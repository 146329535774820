<script>
import EnvSelector from '@/components/proxy/EnvSelector.vue'

const topLinks1 = [
  {
    region: 'Europe',
    links: [
      {
        zone: 'Europe',
        country: 'PL',
        level: '4',
        phone: 'iPhone',
        avg_revenue: '25'
      },
      {
        zone: 'Europe',
        country: 'PL',
        level: '50',
        phone: 'iPhone',
        avg_revenue: '25'
      },
      {
        zone: 'Europe',
        country: 'DE',
        level: '4',
        phone: 'iPhone',
        avg_revenue: '25'
      },
      {
        zone: 'Europe',
        country: 'DE',
        level: '50',
        phone: 'iPhone',
        avg_revenue: '25'
      },
      {
        zone: 'Europe',
        country: 'FR',
        level: '4',
        phone: 'iPhone',
        avg_revenue: '25'
      },
      {
        zone: 'Europe',
        country: 'FR',
        level: '50',
        phone: 'iPhone',
        avg_revenue: '25'
      },
      {
        zone: 'Europe',
        country: 'TR',
        level: '4',
        phone: 'iPhone',
        avg_revenue: '25'
      },
      {
        zone: 'Europe',
        country: 'TR',
        level: '50',
        phone: 'iPhone',
        avg_revenue: '25'
      }
    ]
  },
  {
    region: 'America',
    links: [
      {
        zone: 'America',
        country: 'US',
        level: '4',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'America',
        country: 'US',
        level: '50',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'America',
        country: 'CA',
        level: '4',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'America',
        country: 'CA',
        level: '50',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'America',
        country: 'MX',
        level: '4',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'America',
        country: 'MX',
        level: '50',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'America',
        country: 'CL',
        level: '4',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'America',
        country: 'CL',
        level: '50',
        phone: 'samsung',
        avg_revenue: '25'
      }
    ]
  },
  {
    region: 'Asia',
    links: [
      {
        zone: 'Asia',
        country: 'PH',
        level: '4',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'Asia',
        country: 'PH',
        level: '50',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'Asia',
        country: 'ID',
        level: '4',
        phone: 'samsung',
        avg_revenue: '25'
      },
      {
        zone: 'Asia',
        country: 'ID',
        level: '50',
        phone: 'samsung',
        avg_revenue: '25'
      }
    ]
  }
]
const topLinks2 = [
  {
    region: 'Europe',
    links: [
      {
        zone: 'Europe',
        country: 'PL',
        level: '4',
        phone: 'opooxd',
        avg_revenue: '0'
      },
      {
        zone: 'Europe',
        country: 'PL',
        level: '50',
        phone: 'opooxd',
        avg_revenue: '0'
      },
      {
        zone: 'Europe',
        country: 'DE',
        level: '4',
        phone: 'opooxd',
        avg_revenue: '0'
      },
      {
        zone: 'Europe',
        country: 'DE',
        level: '50',
        phone: 'opooxd',
        avg_revenue: '0'
      },
      {
        zone: 'Europe',
        country: 'FR',
        level: '4',
        phone: 'opooxd',
        avg_revenue: '0'
      },
      {
        zone: 'Europe',
        country: 'FR',
        level: '50',
        phone: 'opooxd',
        avg_revenue: '0'
      },
      {
        zone: 'Europe',
        country: 'TR',
        level: '4',
        phone: 'opooxd',
        avg_revenue: '0'
      },
      {
        zone: 'Europe',
        country: 'TR',
        level: '50',
        phone: 'opooxd',
        avg_revenue: '0'
      }
    ]
  },
  {
    region: 'America',
    links: [
      {
        zone: 'America',
        country: 'US',
        level: '4',
        phone: 'hulalej',
        avg_revenue: '0'
      },
      {
        zone: 'America',
        country: 'US',
        level: '50',
        phone: 'hulalej',
        avg_revenue: '0'
      },
      {
        zone: 'America',
        country: 'CA',
        level: '4',
        phone: 'hulalej',
        avg_revenue: '0'
      },
      {
        zone: 'America',
        country: 'CA',
        level: '50',
        phone: 'hulalej',
        avg_revenue: '0'
      },
      {
        zone: 'America',
        country: 'MX',
        level: '4',
        phone: 'hulalej',
        avg_revenue: '0'
      },
      {
        zone: 'America',
        country: 'MX',
        level: '50',
        phone: 'hulalej',
        avg_revenue: '0'
      },
      {
        zone: 'America',
        country: 'CL',
        level: '4',
        phone: 'hulalej',
        avg_revenue: '0'
      },
      {
        zone: 'America',
        country: 'CL',
        level: '50',
        phone: 'hulalej',
        avg_revenue: '0'
      }
    ]
  }
]

export default {
  components: { EnvSelector },
  setup() {
    return {
      topLinks1,
      topLinks2
    }
  },
  data() {
    return {
      customSearch: {
        countryCode: '',
        level: '',
        avgRevenue: '',
        zone: '',
        phone: ''
      },
      customSearchErrors: {}
    }
  },
  computed: {
    phones() {
      return [
        { label: 'Samsung', value: 'samsung' },
        { label: 'iPhone', value: 'iPhone' },
        { label: 'Słaby telefon', value: 'hulalej' }
      ]
    },
    zones() {
      return this.$utils.getOptionsFromArray(['Europe', 'America', 'Asia'])
    },
    revenues() {
      return [
        { label: '0', value: '0' },
        { label: '>25', value: '25' }
      ]
    },
    countries() {
      return [
        { label: 'INT - International', value: 'INT' },
        ...Object.entries(this.$utils.countries).map(([code, countryData]) => {
          return { label: `${code} - ${countryData.name} ${countryData.emoji}`, value: code }
        })
      ]
    }
  },
  mounted() {
    const $route = this.$route
    this.customSearch.zone = $route.query?.c_sea?.zone
    this.customSearch.countryCode = $route.query?.c_sea?.country
    this.customSearch.phone = $route.query?.c_sea?.phone
    this.customSearch.level = $route.query?.c_sea?.level
    this.customSearch.avgRevenue = $route.query?.c_sea?.avg_revenue
  },
  methods: {
    refreshPage() {
      this.$utils.nextLoopEvent(100).then(() => {
        this.$router.go(0)
      })
    },
    doCustomGuildSearch() {
      if (this.customSearch.zone === '') {
        this.$message.error('Select Zone')
        return
      }
      let env = this.$route.query?.c_sea?.env
      if (!env) {
        env = 'prod'
      }

      const url = this.$router.resolve({
        name: 'gameplay-guild-search-test',
        query: {
          c_sea: {
            zone: this.customSearch.zone,
            country: this.customSearch.countryCode,
            phone: this.customSearch.phone,
            level: this.customSearch.level,
            avg_revenue: this.customSearch.avgRevenue,
            env
          }
        }
      })
      document.location.href = url.href
    }
  }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/guildsearchtest/'
    }"
    :top-actions="{
      excelExport: false,
      importExcel: false,
      addNew: false
    }"
    disable-multi-select-rows
    disable-pagination
    hide-columns-selector
    hide-clear-btn
    hide-refresh-btn
  >
    <template #topLeft="{ searchFields }">
      <div class="w-20">
        <EnvSelector v-model:value-field="searchFields.env" />
      </div>
    </template>
    <template #table="{ data, loading, searchFields }">
      <div
        v-if="data?.rows?.length"
        class="gs-user-font px-4"
        :class="{ 'opacity-30': loading }"
      >
        <el-form
          label-position="top"
          class="gs-scaled"
        >
          <fields-col class="sm:min-w-[1200px]">
            <el-col
              :xs="24"
              :sm="12"
            >
              <h1 class="font-related-xl mt-5 font-semibold">
                SZYBKIE QUERY iPhone/Samsung lub avg_revenue>25
              </h1>
              <div class="font-related-lg mt-2">
                <div
                  v-for="(region, indexRegion) in topLinks1"
                  :key="indexRegion"
                  class="pt-1"
                >
                  <template
                    v-for="(link, indexLink) in region.links"
                    :key="indexLink"
                  >
                    <router-link
                      :to="{
                        name: 'gameplay-guild-search-test',
                        query: {
                          c_sea: { ...link, env: $route.query?.c_sea?.env }
                        }
                      }"
                      class="gs-btn-text-primary underline"
                      @click="refreshPage"
                    >
                      {{ `${link.country}${link.level}` }}
                    </router-link>
                    <span v-if="indexLink + 1 < region.links.length"> | </span>
                  </template>
                  &lt;--- {{ region.region }}
                </div>
              </div>
              <h1 class="font-related-xl mt-5 font-semibold">
                SZYBKIE QUERY telefon słaby i avg_revenue=0
              </h1>
              <div class="font-related-lg mt-2">
                <div
                  v-for="(region, indexRegion) in topLinks2"
                  :key="indexRegion"
                  class="pt-1"
                >
                  <template
                    v-for="(link, indexLink) in region.links"
                    :key="indexLink"
                  >
                    <router-link
                      :to="{
                        name: 'gameplay-guild-search-test',
                        query: {
                          c_sea: { ...link }
                        }
                      }"
                      class="gs-btn-text-primary underline"
                      @click="refreshPage"
                    >
                      {{ `${link.country}${link.level}` }}
                    </router-link>
                    <span v-if="indexLink + 1 < region.links.length"> | </span>
                  </template>
                  &lt;--- {{ region.region }}
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
            >
              <h1 class="font-related-xl mb-2 mt-5 font-semibold">
                Niestandardowe wyszukiwanie
              </h1>
              <fields-col class="w-96">
                <crud-field-select
                  :span="12"
                  api-field-name="countryCode"
                  :form="customSearch"
                  :external-errors="customSearchErrors"
                  :options="countries"
                  @change="customSearch.zone = ''"
                />
                <crud-field-select
                  :span="12"
                  api-field-name="zone"
                  :form="customSearch"
                  :external-errors="customSearchErrors"
                  :options="zones"
                />
                <crud-field-number
                  :span="12"
                  api-field-name="level"
                  :form="customSearch"
                  :external-errors="customSearchErrors"
                  col-class="-mt-3"
                />
                <crud-field-select
                  :span="12"
                  api-field-name="avgRevenue"
                  label="Revenue"
                  :form="customSearch"
                  :external-errors="customSearchErrors"
                  :options="revenues"
                  col-class="-mt-3"
                />
                <crud-field-select
                  :span="12"
                  api-field-name="phone"
                  :form="customSearch"
                  :external-errors="customSearchErrors"
                  :options="phones"
                  col-class="-mt-3"
                />
                <crud-field-slot
                  :span="12"
                  label="Szukaj btn"
                  empty-label
                  col-class="-mt-3"
                >
                  <el-button
                    class="gs-font-scaled gs-height-related-xl"
                    size="small"
                    @click="doCustomGuildSearch"
                  >
                    Szukaj
                  </el-button>
                </crud-field-slot>
              </fields-col>
            </el-col>
          </fields-col>
        </el-form>
        <h1 class="font-related-xxl mt-5 font-semibold">
          Wyniki dla gracza {{ $route.query?.c_sea?.zone }}/{{ $route.query?.c_sea?.country }}/{{
            $route.query?.c_sea?.phone
          }}(tier for new:{{ data?.new_tier }}, tier for old:{{ data?.old_tier }})/Old players lvl:{{
            $route.query?.c_sea?.level
          }}
        </h1>
        <filtering-table
          :rows="data.rows"
          :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
          header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
          cell-class-name="gs-col-padding-mini"
          fit
          :columns="{
            name: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 200 } },
            description: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 200 } },
            description_change: { header: 'Description changed at', sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
            created: { header: 'Created at', sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
            zone: { sortable: true, filterField: true, type: 'string', elAttr: { width: 80 } },
            country: { sortable: true, filterField: true, type: 'string', elAttr: { width: 80 } },
            open: { sortable: true, filterField: true, type: 'bool', elAttr: { width: 80 } },
            division_no: { header: 'DivisionNo', sortable: true, filterField: true, type: 'int', elAttr: { width: 90 } },
            req_level: { header: 'ReqLvl', sortable: true, filterField: true, type: 'int', elAttr: { width: 90 } },
            group_no: { header: 'GroupNo', sortable: true, filterField: true, type: 'int', elAttr: { width: 90 } },
            power: { sortable: true, filterField: true, type: 'int', elAttr: { width: 90 } },
            players: { sortable: true, filterField: true, type: 'string', elAttr: { width: 100 } },
            skirmish_activity: {
              header: 'SkirmishActivity%',
              sortable: true,
              filterField: true,
              type: 'string',
              elAttr: { width: 130 }
            },
            players_activity: {
              header: 'PlayersActivity%',
              sortable: true,
              filterField: true,
              type: 'string',
              elAttr: { width: 120 }
            },
            test_stage: { header: 'TestStage', sortable: true, filterField: true, type: 'int', elAttr: { width: 90 } },
            bi_score_new: { header: 'BiScore new', sortable: true, filterField: true, type: 'float', elAttr: { width: 100 } }
          }"
          index-rows-column
          :page-sizes="[50, 100, 200]"
          hide-refresh-btn
        >
          <template #cell_name="{ row }">
            <router-link
              :to="{ name: 'gameplay-guild-list-details', params: { id: row.guild.id }, query: { env: searchFields.env } }"
              class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
            >
              {{ row.guild.name }}
            </router-link>
          </template>
        </filtering-table>

        <div class="font-related-xl mt-10 leading-normal">
          <div class="mb-1">
            <b>TestStage</b> - wyjaśnienie:
          </div>
          <div>(1) - szukanie recommended dla 1 dywizji, 40% z kraju, 35% z INT, 25% reszta</div>
          <div>(2) - gdy brak przynajmniej 3 wynikiów z kroku (1), szukanie też recommended - ale od dywizji nr 2</div>
          <div>(3) - nowo dodane gildie - gdy z (1) i (2) nie ma żadnych wyników</div>
          <div class="mt-5">
            (10) - gildie z algorytmu autoinvite
          </div>
          <div>(11) - gdy brak 14 wyników z (10) gildie z szukajki algorytmu recommended dla wszystkich dywizji</div>
        </div>

        <div class="font-related-xl mt-10">
          Debug message
        </div>
        <pre v-html="data?.debug_msg" />
      </div>
      <div v-else-if="loading">
        loading...
      </div>
      <div v-else-if="!loading && data?.rows !== undefined">
        no data
      </div>
      <div />
    </template>
  </crud-table>
</template>
