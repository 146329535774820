<script>
export default {
  name: 'ZoneSelector',
  props: {
    typeValueField: {
      type: undefined,
      default: undefined
    }
  }
}
</script>

<template>
  <crud-type-fields
    v-bind="$props"
    type-value-field="select"
    full-width
    options-enum="enums.Zone"
    init-value="Europe"
    label="Zone"
    :clearable="false"
  />
</template>

<style scoped></style>
