<script setup>
</script>

<template>
  <crud-details-page
    api="season-migration"
    disable-top-margin
  >
    <template #form>
      <fields-col>
        <crud-field-number
          :sm="6"
          api-field-name="SeasonNo"
          label="SeasonNo (migration from seasonNo to the next one)"
        />
        <crud-field-text
          :sm="12"
          api-field-name="Label"
        />
      </fields-col>

      <fields-col> TOOLS: </fields-col>
      <fields-col>
        See division preview for PROD: &nbsp;
        <template
          v-for="(zone, index) in ['Europe', 'America', 'Asia']"
          :key="zone"
        >
          <template v-if="index">
            &nbsp;|&nbsp;
          </template>
          <router-link
            :to="
              $utils.bindStaticParams({
                name: 'gameplay-divisions',
                query: {
                  c_sea: {
                    env: 'prod',
                    zone,
                    with_migration_sim: 0
                  }
                }
              })
            "
            target="_blank"
            class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
          >
            {{ zone }}
          </router-link>
        </template>
      </fields-col>
      <fields-col>
        See migration simulation for PROD: &nbsp;
        <template
          v-for="(zone, index) in ['Europe', 'America', 'Asia']"
          :key="zone"
        >
          <template v-if="index">
            &nbsp;|&nbsp;
          </template>
          <router-link
            :to="
              $utils.bindStaticParams({
                name: 'gameplay-divisions',
                query: {
                  c_sea: {
                    env: 'prod',
                    zone,
                    with_migration_sim: 1
                  }
                }
              })
            "
            target="_blank"
            class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
          >
            {{ zone }}
          </router-link>
        </template>
      </fields-col>

      <fields-col :sm="16">
        <template
          v-for="zone in ['Europe', 'America', 'Asia']"
          :key="zone"
        >
          <section-divider>Settings for {{ zone }}</section-divider>
          <template
            v-for="no in [1, 2, 3, 4, 5]"
            :key="no"
          >
            <fields-col>
              <crud-field-slot :sm="3">
                <div style="margin-top: 30px">
                  Division {{ no }}
                </div>
              </crud-field-slot>
              <crud-field-select
                :sm="8"
                :api-field-name="`${zone}.Div${no}.Distribution`"
                options-enum="enums.MigrationDistributionType"
                required
              />
              <crud-field-number
                :sm="4"
                :api-field-name="`${zone}.Div${no}.Up`"
              />
              <crud-field-number
                :sm="4"
                :api-field-name="`${zone}.Div${no}.Down`"
              />
            </fields-col>
          </template>
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
