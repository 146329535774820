<script setup></script>

<template>
  <crud-details-page
    api="settings/device-flag"
    :versioned="false"
  >
    <template #form>
      <!-- DeviceId -->
      <crud-field-text
        :sm="8"
        api-field-name="DeviceId"
        required
      />
      <fields-col :sm="8">
        <!-- IsBugReporterEnabled -->
        <crud-field-switcher api-field-name="IsBugReporterEnabled" />
        <!-- IsGuestButtonEnabled -->
        <crud-field-switcher api-field-name="IsGuestButtonEnabled" />
        <!-- SkipTutorial -->
        <crud-field-switcher api-field-name="SkipTutorial" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
