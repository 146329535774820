<script>
import CrudFieldSelectorSkinGroup from '@/components/forms/crud-fields/CrudFieldSelectorSkinGroup.vue'

export default {
  components: { CrudFieldSelectorSkinGroup }
}
</script>

<template>
  <crud-details-page
    api="cosmetics/skin"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Quality -->
        <crud-field-number api-field-name="Quality" />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.SkinType"
          required
        />
        <!-- AssetMale -->
        <crud-field-selector-files api-field-name="AssetMale" />
        <!-- AssetFemale -->
        <crud-field-selector-files api-field-name="AssetFemale" />
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- AssetIconFemale -->
        <crud-field-selector-files api-field-name="AssetIconFemale" />
      </fields-col>
      <fields-col :sm="8">
        <!-- Color1 -->
        <crud-field-text
          v-if="!$isTheGang"
          api-field-name="Color1"
          label="Color right"
        />
        <!-- Color2 -->
        <crud-field-text
          v-if="!$isTheGang"
          api-field-name="Color2"
          label="Color left"
        />
        <!-- Color1 -->
        <crud-field-text api-field-name="Color1" />
        <!-- Color2 -->
        <crud-field-text api-field-name="Color2" />
        <!-- ExtraSkin -->
        <crud-field-selector-skin api-field-name="ExtraSkin" />
        <!-- SkinGroup -->
        <CrudFieldSelectorSkinGroup
          v-if="$isTheGang"
          api-field-name="SkinGroup"
        />
        <!-- CardNameTag -->
        <crud-field-selector-client-strings
          label="Name Tag"
          api-field-name="CardNameTag"
        />
        <fields-col
          :sm="12"
          class="px-10"
        >
          <!-- OnlyDummies -->
          <crud-field-checkbox
            api-field-name="OnlyDummies"
            slim
          />
          <!-- ForPlayerBot -->
          <crud-field-checkbox
            api-field-name="ForPlayerBot"
            slim
          />
          <!-- HideEars -->
          <crud-field-checkbox
            api-field-name="HideEars"
            slim
          />
          <!-- MysteryChestExclude -->
          <crud-field-checkbox
            api-field-name="MysteryChestExclude"
            slim
          />
        </fields-col>
      </fields-col>
      <fields-col :sm="8">
        <section-divider>only for horses</section-divider>
        <!-- MountDurationHours -->
        <crud-field-number api-field-name="MountDurationHours" />
        <!-- BonusAttackPct -->
        <crud-field-number api-field-name="BonusAttackPct" />
        <!-- BonusXpPct -->
        <crud-field-number api-field-name="BonusXpPct" />
        <!-- BonusGiftsPct -->
        <crud-field-number api-field-name="BonusGiftsPct" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
