<template>
  <crud-details-page
    api="rewards/trophy"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- DivisionNo -->
        <crud-field-number
          api-field-name="DivisionNo"
          required
        />
        <!-- PlaceNo -->
        <crud-field-number api-field-name="PlaceNo" />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.TrophyType"
          required
        />
        <!-- AssetTrophy -->
        <crud-field-selector-files
          api-field-name="AssetTrophy"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
