<script>
import dayjs from 'dayjs'
import { userRightRoles } from '!/composition/utilities'

export default {
  props: {
    menuItem: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      userRightRoles
    }
  },
  data() {
    return {
      form: {},
      loading: {},
      log: '',
      env: '',
      envApi: '',
      ccBlockade: null,
      loadingSkirmishes: false,
      loadingCache: {},
      dialogVisible: false,
      activeBattle: false,
      activeBattleTimer: null,
      timeShiftForm: {
        log: '',
        isHtmlLog: false,
        logError: false,
        copyLog: '',
        isHtmlCopyLog: false,
        copyLogError: false,
        loading: {}
      },
      betaPtrForm: {
        log: '',
        isHtmlLog: false,
        logError: false,
        copyLog: '',
        isHtmlCopyLog: false,
        copyLogError: false,
        loading: {}
      }
    }
  },
  mounted() {
    this.checkIfActiveBattle()
    this.activeBattleTimer = setInterval(() => { // check if skirmish is taking place every 15 second on this page
      this.checkIfActiveBattle()
    }, 15000)
  },
  beforeUnmount() {
    clearInterval(this.activeBattleTimer)
  },
  created() {
    this.env = 'dev'
    this.envApi = this.$store.getters['auth/envs']?.[this.env]
    this.getClearCacheBlockade()
  },
  methods: {
    checkIfActiveBattle() {
      const email = this.$store.getters['auth/userData'].Email
      const envApiProd = this.$store.getters['auth/envs']?.prod

      this.$axios
        .get(`${envApiProd}/proxy/getbattlehours`, { params: { u: email, p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K' } })
        .then((response) => {
          this.activeBattle = response.data.active_battle
        })
        .catch(() => {
          this.loadingSkirmishes = false
          this.$message({
            message: 'Show skirmishes failed',
            type: 'warning',
            offset: 40
          })
        })
    },
    clearCache(env) {
      this.loadingCache[env] = true
      this.$axios
        .get(`/admin/api/proxy/request/`, { params: { method: 'GET', data: [], uri: '/proxy/clearcache/', env } })
        .then(() => {
          this.loadingCache[env] = false
          this.$message({
            message: `${env.toUpperCase()} cache cleared`,
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loadingCache[env] = false
          this.$message({
            message: `${env.toUpperCase()} cache failed`,
            type: 'warning',
            offset: 40
          })
        })
    },
    clearGameplayCache(env) {
      this.loadingCache[env] = true
      this.$axios
        .get(`/admin/api/proxy/request/`, {
          params: {
            method: 'GET',
            data: [],
            uri: '/proxy/cleargameplaycache/',
            env
          }
        })
        .then(() => {
          this.loadingCache[env] = false
          this.$message({
            message: `${env.toUpperCase()} gameplay cache cleared`,
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loadingCache[env] = false
          this.$message({
            message: `${env.toUpperCase()} gameplay cache failed`,
            type: 'warning',
            offset: 40
          })
        })
    },
    showSkirmishes() {
      this.loadingSkirmishes = true
      const envApi = this.$store.getters['auth/envs'].prod
      this.$axios
        .get(`${envApi}/proxy/listbattlehours/`)
        .then((response) => {
          this.loadingSkirmishes = false
          this.dialogVisible = true
          this.$utils.nextLoopEvent(100).then(() => {
            const doc = this.$refs.iframe.contentWindow.document
            doc.open()
            doc.write(response.data)
            doc.close()
          })
        })
        .catch(() => {
          this.loadingSkirmishes = false
          this.$message({
            message: 'Show skirmishes failed',
            type: 'warning',
            offset: 40
          })
        })
    },
    getTime(env, formName) {
      if (!this.envApi) {
        return false
      }
      this.$refs?.[formName]
        ?.validate?.()
        .then(() => {
          this.$axios
            .get(`/admin/api/proxy/request/`, { params: { method: 'GET', data: [], uri: '/dev/getdate/', env } })
            .then(({ data }) => {
              this.$data[formName].dateInput = data
              this.notify(data)
            })
            .catch((err) => {
              this.$utils.catchError(err)
            })
        })
        .catch(() => {
        })
    },
    changeTime(env, formName) {
      if (!this.envApi) {
        return false
      }
      this.$refs?.[formName]
        ?.validate?.()
        .then(() => {
          this.$axios
            .get(`/admin/api/proxy/request/`, {
              params: {
                method: 'GET',
                data: [],
                uri: '/dev/changedate/',
                env,
                date: dayjs(this.$data[formName].dateInput).format('YYYY-MM-DD_HH:mm:ss')
              }
            })
            .then(({ data }) => {
              this.notify(data)
            })
            .catch((err) => {
              this.$utils.catchError(err)
            })
        })
        .catch(() => {
        })
    },
    copyDbToTs(dbToCopy, region, formName) {
      if (!this.envApi) {
        return false
      }
      this.$data[formName].loading[`copyDb_${dbToCopy}_${region}`] = true
      this.$data[formName].copyLog = ''
      this.$data[formName].copyLogError = false
      this.$axios
        .get(`${this.envApi}/dev/dupdb${dbToCopy}/`, {
          params: {
            region
          }
        })
        .then(({ data }) => {
          try {
            if (data?.logs) {
              this.$data[formName].copyLog = data.logs
              this.$data[formName].isHtmlCopyLog = true
            } else {
              this.$data[formName].copyLog = JSON.stringify(data, null, '\t')
            }
            this.$data[formName].copyLogError = data?.status !== 'ok'
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
          this.$data[formName].loading[`copyDb_${dbToCopy}_${region}`] = false
          this.$message({
            message: 'Database duplicated',
            type: 'success',
            offset: 40
          })
        })
        .catch((data) => {
          try {
            if (data?.response?.data?.errors?.[0]) {
              this.$data[formName].copyLog = data.response.data.errors[0]
              this.$data[formName].isHtmlCopyLog = true
            } else {
              this.$data[formName].copyLog = JSON.stringify(data?.response || data, null, '\t')
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
          this.$data[formName].copyLogError = true
          this.$data[formName].loading[`copyDb_${dbToCopy}_${region}`] = false
          this.$data[formName].$message({
            message: 'Database copy failed',
            type: 'warning',
            offset: 40
          })
        })
    },
    getClearCacheBlockade() {
      this.$axios
        .get('/dev/getclearcacheblockade/', {})
        .then(({ data }) => {
          try {
            if (data?.blockade) {
              this.ccBlockade = data.blockade
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
        })
        .catch(() => {
        })
    },
    blockClearCache() {
      this.loading.blockClearCache = true
      this.$axios
        .get('/dev/blockclearcache/', {})
        .then(() => {
          this.loading.blockClearCache = false
          this.getClearCacheBlockade()
          this.$message({
            message: 'Clear cache blocked',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loading.blockClearCache = false
          this.$message({
            message: 'Error while setting clear cache blockade',
            type: 'warning',
            offset: 40
          })
        })
    },
    unblockClearCache() {
      this.loading.blockClearCache = true
      this.$axios
        .get('/dev/unblockclearcache/', {})
        .then(() => {
          this.loading.blockClearCache = false
          this.ccBlockade = null
          this.$message({
            message: 'Clear cache unblocked',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loading.blockClearCache = false
          this.$message({
            message: 'Error while unsetting clear cache blockade',
            type: 'warning',
            offset: 40
          })
        })
    },
    notify(message = 'success') {
      this.$message({
        message,
        type: 'success',
        offset: 40
      })
    }
  }
}
</script>

<template>
  <div class="px-4">
    <el-collapse class="gs-inverted">
      <el-collapse-item title="Timeshift actions">
        <el-row>
          <el-form
            ref="timeShiftForm"
            label-position="top"
            :model="timeShiftForm"
            style="min-width: 1000px"
          >
            <fields-col :span="8">
              <el-col>
                <crud-field-date-time
                  api-field-name="dateInput"
                  :form="timeShiftForm"
                  :external-errors="{}"
                />
                <el-button
                  type="primary"
                  class="m-5"
                  @click="getTime('timeshift', 'timeShiftForm')"
                >
                  Get date and time
                </el-button>
                <el-popconfirm
                  title="Are you sure?"
                  :teleported="false"
                  @confirm="changeTime('timeshift', 'timeShiftForm')"
                >
                  <template #reference>
                    <el-button
                      type="primary"
                      class="m-5"
                    >
                      Change date and time
                    </el-button>
                  </template>
                </el-popconfirm>
              </el-col>
            </fields-col>
          </el-form>
        </el-row>
        <el-row>
          <el-col>
            <el-popconfirm
              title="Are you sure?"
              :teleported="false"
              @confirm="copyDbToTs('static', 'time', 'timeShiftForm')"
            >
              <template #reference>
                <el-button
                  type="primary"
                  class="m-5"
                  :loading="timeShiftForm.loading.copyDb_static_time"
                >
                  Copy Static DB to Timeshift (uses DEV data)
                </el-button>
              </template>
            </el-popconfirm>
            <el-button
              type="warning"
              class="m-5"
              :loading="loadingCache.timeshift"
              @click="clearCache('timeshift')"
            >
              CLEAR TIMESHIFT
            </el-button>
          </el-col>
          <el-col>
            <el-popconfirm
              title="Are you sure?"
              :teleported="false"
              @confirm="copyDbToTs('gameplay', 'time', 'timeShiftForm')"
            >
              <template #reference>
                <el-button
                  type="primary"
                  class="m-5"
                  :loading="timeShiftForm.loading.copyDb_gameplay_time"
                >
                  Copy Gameplay DB from PROD to Timeshift
                </el-button>
              </template>
            </el-popconfirm>
            <el-button
              type="warning"
              class="m-5"
              :loading="loadingCache.timeshift"
              @click="clearGameplayCache('timeshift')"
            >
              CLEAR GAMEPLAY TIMESHIFT
            </el-button>
          </el-col>
          <transition name="el-fade-in-linear">
            <div
              v-if="timeShiftForm.copyLog.length"
              class="gs-font-scaled px-5"
            >
              <el-divider content-position="left">
                DB copy log
              </el-divider>
              <div
                v-if="timeShiftForm.isHtmlCopyLog"
                class="mx-5 p-5"
                :class="[timeShiftForm.copyLogError ? 'bg-red-100' : 'bg-green-100']"
                v-html="timeShiftForm.copyLog"
              />
              <pre
                v-else
                class="mx-5 p-5"
                :class="[timeShiftForm.copyLogError ? 'bg-red-100' : 'bg-green-100']"
              >{{ timeShiftForm.copyLog }}</pre>
            </div>
          </transition>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="Beta-PTR actions">
        <el-row>
          <el-form
            ref="betaPtrForm"
            :model="betaPtrForm"
            label-position="top"
            style="min-width: 1000px"
          >
            <fields-col :span="8">
              <el-col>
                <crud-field-date-time
                  api-field-name="dateInput"
                  :form="betaPtrForm"
                  :external-errors="{}"
                />
                <el-button
                  type="primary"
                  class="m-5"
                  @click="getTime('beta', 'betaPtrForm')"
                >
                  Get date and time
                </el-button>
                <el-popconfirm
                  title="Are you sure?"
                  :teleported="false"
                  @confirm="changeTime('beta', 'betaPtrForm')"
                >
                  <template #reference>
                    <el-button
                      type="primary"
                      class="m-5"
                      disabled
                    >
                      Change date and time
                    </el-button>
                  </template>
                </el-popconfirm>
              </el-col>
            </fields-col>
          </el-form>
        </el-row>
        <el-row>
          <el-col>
            <el-popconfirm
              title="Are you sure?"
              :teleported="false"
              @confirm="copyDbToTs('static', 'beta', 'betaPtrForm')"
            >
              <template #reference>
                <el-button
                  type="primary"
                  class="m-5"
                  :loading="betaPtrForm.loading.copyDb_static_beta"
                >
                  Copy Static DB to Beta-PTR (uses DEV data)
                </el-button>
              </template>
            </el-popconfirm>
            <el-button
              type="warning"
              class="m-5"
              :loading="loadingCache.beta"
              @click="clearCache('beta')"
            >
              CLEAR BETA
            </el-button>
          </el-col>
          <el-col>
            <el-popconfirm
              title="Are you sure?"
              :teleported="false"
              @confirm="copyDbToTs('gameplay', 'beta', 'betaPtrForm')"
            >
              <template #reference>
                <el-button
                  type="primary"
                  class="m-5"
                  :loading="betaPtrForm.loading.copyDb_gameplay_beta"
                >
                  Copy Gameplay DB from PROD to Beta-PTR
                </el-button>
              </template>
            </el-popconfirm>
            <el-button
              type="warning"
              class="m-5"
              :loading="loadingCache.beta"
              @click="clearGameplayCache('beta')"
            >
              CLEAR GAMEPLAY BETA
            </el-button>
          </el-col>
          <transition name="el-fade-in-linear">
            <div
              v-if="betaPtrForm.copyLog.length"
              class="gs-font-scaled px-5"
            >
              <el-divider content-position="left">
                DB copy log
              </el-divider>
              <div
                v-if="betaPtrForm.isHtmlCopyLog"
                class="mx-5 p-5"
                :class="[betaPtrForm.copyLogError ? 'bg-red-100' : 'bg-green-100']"
                v-html="betaPtrForm.copyLog"
              />
              <pre
                v-else
                class="mx-5 p-5"
                :class="[betaPtrForm.copyLogError ? 'bg-red-100' : 'bg-green-100']"
              >{{ betaPtrForm.copyLog }}</pre>
            </div>
          </transition>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
  <template v-if="ccBlockade === null">
    <el-popconfirm
      title="Are you sure?"
      :teleported="false"
      @confirm="blockClearCache"
    >
      <template #reference>
        <el-button
          type="primary"
          class="m-5"
          :loading="loading.blockClearCache"
        >
          BLOCK CC
        </el-button>
      </template>
    </el-popconfirm>
  </template>
  <template v-if="ccBlockade !== null">
    <el-tooltip
      class="box-item"
      placement="top"
      :content="`Clear cache blocked by: ${ccBlockade.AdminUser}`"
    >
      <span>
        <el-popconfirm
          title="Are you sure?"
          :teleported="false"
          @confirm="unblockClearCache"
        >
          <template #reference>
            <el-button
              type="primary"
              class="m-5"
              :loading="loading.blockClearCache"
            >
              UNBLOCK CC
            </el-button>
          </template>
        </el-popconfirm>
      </span>
    </el-tooltip>
  </template>
  <el-button
    type="warning"
    class="m-5"
    :loading="loadingCache.dev"
    @click="clearCache('dev')"
  >
    CLEAR DEV
  </el-button>
  <template v-if="ccBlockade !== null || activeBattle">
    <el-tooltip
      class="box-item"
      placement="top"
      :content="activeBattle ? 'Active battle' : `Clear cache blocked by: ${ccBlockade.AdminUser}`"
    >
      <span>
        <el-button
          disabled
          type="danger"
          class="m-5"
          :loading="loadingCache.prod"
        >CLEAR PROD</el-button>
      </span>
    </el-tooltip>
  </template>
  <template v-if="ccBlockade === null && !activeBattle">
    <el-popconfirm
      title="Are you sure ?"
      :teleported="false"
      @confirm="clearCache('prod')"
    >
      <template #reference>
        <el-button
          :disabled="ccBlockade !== null || activeBattle"
          type="danger"
          class="m-5"
          :loading="loadingCache.prod"
        >
          CLEAR PROD
        </el-button>
      </template>
    </el-popconfirm>
  </template>
  <el-button
    type="info"
    class="m-5"
    :loading="loadingSkirmishes"
    @click="showSkirmishes"
  >
    SHOW PROD SKIRMISHES
  </el-button>
  <transition name="el-fade-in-linear">
    <div
      v-if="log.length"
      class="gs-font-scaled px-5"
    >
      <el-divider content-position="left">
        Log
      </el-divider>
      <div
        v-if="isHtmlLog"
        class="mx-5 p-5"
        :class="[logError ? 'bg-red-100' : 'bg-green-100']"
        v-html="log"
      />
      <pre
        v-else
        class="mx-5 p-5"
        :class="[logError ? 'bg-red-100' : 'bg-green-100']"
      >{{ log }}</pre>
    </div>
  </transition>
  <div />
  <el-dialog
    v-model="dialogVisible"
    title="PROD SKIRMISHES"
    width="70%"
    destroy-on-close
  >
    <iframe
      ref="iframe"
      class="h-[60vh] min-h-[500px] w-full"
    />
  </el-dialog>
  <div v-if="$route.query?.tdl === '1'">
    <h1 class="p-3">
      Otwierające się w tym samym tab przeglądarki:
    </h1>
    <div class="p-5">
      <a
        href="com.gamesture.socialwars.dev://gamesture-store-dev.vercel.app/deep-link/game/dev-slash-and-roll"
        target="_blank"
        class="underline text-blue-500 hover:text-cyan-700 break-all"
      >com.gamesture.socialwars.dev://gamesture-store-dev.vercel.app/deep-link/game/dev-slash-and-roll</a>
    </div>
    <div class="p-5">
      <a
        href="https://gamesture-store-dev.vercel.app/deep-link/game/dev-slash-and-roll?route=game-page&gameKey=slashAndRoll&offerId=offer211"
        class="underline text-blue-500 hover:text-cyan-700 break-all"
      >https://gamesture-store-dev.vercel.app/deep-link/game/dev-slash-and-roll?route=game-page&gameKey=slashAndRoll&offerId=offer211</a>
    </div>
    <h1 class="p-3">
      Otwierające się w nowym tab przeglądarki:
    </h1>
    <div class="p-5">
      <a
        href="com.gamesture.socialwars.dev://gamesture-store-dev.vercel.app/deep-link/game/dev-slash-and-roll"
        target="_blank"
        class="underline text-blue-500 hover:text-cyan-700 break-all"
      >com.gamesture.socialwars.dev://gamesture-store-dev.vercel.app/deep-link/game/dev-slash-and-roll</a>
    </div>
    <div class="p-5">
      <a
        href="https://gamesture-store-dev.vercel.app/deep-link/game/dev-slash-and-roll?route=game-page&gameKey=slashAndRoll&offerId=offer211"
        target="_blank"
        class="underline text-blue-500 hover:text-cyan-700 break-all"
      >https://gamesture-store-dev.vercel.app/deep-link/game/dev-slash-and-roll?route=game-page&gameKey=slashAndRoll&offerId=offer211</a>
    </div>
  </div>
</template>
