<script setup>
function renderLoadedData(response) {
  return { items: response.rows, total: response.rows.length }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/seasonptsranking/'
    }"
    :action-col-settings="false"
    :top-actions="false"
    :static-api-params="{
      env: 'prod'
    }"
    :columns="{
      name: 'string:sort',
      created: 'string:sort',
      level: 'int:sort',
      points: 'int:sort',
      last_active: 'string:sort',
      info: 'string:sort',
      guild: 'string:sort'
    }"
    :columns-settings="{
      ID: {
        elAttr: {
          formatter: (row) => row.id
        },
        filterField: true
      },
      name: { width: 260, filterField: true },
      created: { width: 180 },
      level: { width: 60 },
      points: { width: 70 },
      last_active: { width: 180, header: 'Last active' },
      info: { width: 260 },
      guild: { width: 230 }
    }"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800'
    }"
    enable-number-rows
    hide-columns-selector
    :api-pagination="false"
    :api-filtering="false"
    :api-sorting="false"
    :enable-cell-edit-dialogs="false"
    :render-loaded-data="renderLoadedData"
    disable-multi-select-rows
  />
</template>
