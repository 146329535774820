<template>
  <crud-details-page
    api="tools/v2-console-command"
    :versioned="false"
  >
    <template #form>
      <fields-col :sm="14">
        <!-- Command -->
        <crud-field-text
          api-field-name="Command"
          required
        />
        <!-- Args -->
        <crud-field-text
          api-field-name="Args"
          required
        />
        <!-- Description -->
        <crud-field-textarea
          api-field-name="Description"
          required
        />
        <!-- IsClient -->
        <crud-field-switcher api-field-name="IsClient" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
