<script>
export default {
  data() {
    return {
      moveFields: ['OneXpWeight', 'TwoXpWeight', 'ThreeXpWeight', 'OneBigXpWeight', 'TwoBigXpWeight', 'ThreeBigXpWeight', 'AttackWeight', 'CritWeight', 'ShieldWeight', 'EnergyWeight', 'GiftWeight']
    }
  },
  computed: {
    moveChance() {
      return (form, value) => {
        const moveFields = ['OneXpWeight', 'TwoXpWeight', 'ThreeXpWeight', 'OneBigXpWeight', 'TwoBigXpWeight', 'ThreeBigXpWeight', 'AttackWeight', 'CritWeight', 'ShieldWeight', 'EnergyWeight', 'GiftWeight']

        let weightSum = 0
        moveFields.forEach((move) => {
          weightSum += form[move]
        })

        const chance = 100 * (form[value] / weightSum)
        return chance.toFixed(2)
      }
    },
    overallMoveChance() {
      return (form, value1, value2, value3) => {
        const moveFields = ['OneXpWeight', 'TwoXpWeight', 'ThreeXpWeight', 'OneBigXpWeight', 'TwoBigXpWeight', 'ThreeBigXpWeight', 'AttackWeight', 'CritWeight', 'ShieldWeight', 'EnergyWeight', 'GiftWeight']

        let weightSum = 0
        moveFields.forEach((move) => {
          weightSum += form[move]
        })

        const chance = 100 * ((form[value1] + form[value2] + form[value3]) / weightSum)
        return chance.toFixed(2)
      }
    }
  }
}
</script>

<template>
  <crud-details-page
    api="pve-challenge-setting"
    disable-top-margin
  >
    <template #form="{ form }">
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- MoveInterval -->
        <crud-field-number
          api-field-name="MoveInterval"
          required
        />
        <!-- MoveIntervalSkirmish -->
        <crud-field-number
          api-field-name="MoveIntervalSkirmish"
          required
        />
      </fields-col>
      <section-divider>Moves</section-divider>
      <fields-col :sm="24">
        <template
          v-for="(row, index) in moveFields"
          :key="row"
        >
          <crud-field-number
            :sm="8"
            :api-field-name="row"
          />
          <crud-field-slot
            :sm="8"
            label="Chance (in %)"
          >
            {{ moveChance(form, row) }}
          </crud-field-slot>
          <crud-field-slot
            v-if="index === 2 || index === 5"
            :sm="8"
            label="Overall Chance (in %)"
          >
            {{ overallMoveChance(form, moveFields[index], moveFields[index - 1], moveFields[index - 2]) }}
          </crud-field-slot>
          <crud-field-slot
            v-else
            :sm="8"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
