<script setup>
function renderLoadedData(response) {
  return { items: response.rows, total: response.rows.length }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/welcomeplayerslist/'
    }"
    :top-actions="false"
    :action-col-settings="false"
    :static-api-params="{
      env: 'prod'
    }"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800'
    }"
    enable-number-rows
    hide-columns-selector
    :api-pagination="false"
    :api-filtering="false"
    :api-sorting="false"
    :enable-cell-edit-dialogs="false"
    disable-multi-select-rows
    :render-loaded-data="renderLoadedData"
    :columns="{
      name: 'string:sort',
      guild: 'string:sort',
      zone: 'string:sort',
      country: 'string:sort',
      created: 'string:sort',
      level: 'int:sort',
      revenue: 'float:sort',
      guild_members: 'int:sort'
    }"
    :columns-settings="{
      ID: {
        elAttr: {
          formatter: (row) => row.id
        },
        filterField: true
      },
      name: { width: 150, filterField: true },
      guild: { width: 150, filterField: true, elAttr: { formatter: (row) => row?.guild?.name } },
      zone: { width: 80 },
      country: { width: 80 },
      created: { width: 180 },
      level: { width: 60 },
      revenue: { width: 80 },
      guild_members: { width: 120, header: 'Guild Members' }
    }"
  >
    <template #topLeft="{ searchFields }">
      <div class="ml-2 w-28">
        <crud-type-fields
          v-model:value-field="searchFields.last_active"
          :min="0"
          :init-value="72"
          type-value-field="int"
          full-width
          label="Last Active Hours (<=)"
        />
      </div>
      <div class="ml-2 w-28">
        <crud-type-fields
          v-model:value-field="searchFields.days"
          :min="0"
          :init-value="7"
          type-value-field="int"
          full-width
          label="Days (<=)"
        />
      </div>
      <div class="ml-2 w-28">
        <crud-type-fields
          v-model:value-field="searchFields.revenue"
          :min="0"
          :init-value="0.5"
          type-value-field="float"
          full-width
          label="Revenue (>=)"
        />
      </div>
      <div class="ml-2 w-28">
        <crud-type-fields
          v-model:value-field="searchFields.guild_members"
          :min="0"
          :init-value="10"
          type-value-field="int"
          full-width
          label="Guild Members (<=)"
        />
      </div>
      <div class="ml-2 w-48 pr-2">
        <crud-type-fields
          v-model:value-field="searchFields.exclude_welcome_message_sent"
          :min="0"
          :init-value="true"
          type-value-field="bool"
          full-width
          label="Exclude Players With Message Sent"
        />
      </div>
    </template>
    <template #cell_ID="{ row }">
      <router-link
        :to="{ name: 'gameplay-players-details', params: { id: row?.id }, query: { env: 'prod' } }"
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
      >
        {{ row?.id }}
      </router-link>
    </template>
    <template #cell_guild="{ row }">
      <router-link
        :to="{ name: 'gameplay-guild-list-details', params: { id: row?.guild?.id }, query: { env: 'prod' } }"
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
      >
        {{ row?.guild?.name }}
      </router-link>
    </template>
    <template #tablee="{ data, loading, height }">
      <div
        v-if="data?.rows?.length"
        class="px-4"
        :class="{ 'opacity-30': loading }"
      >
        <el-table
          :height="height"
          :data="data.rows"
          table-layout="fixed"
          class="gs-scaled"
          header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
          cell-class-name="gs-col-padding-mini"
        >
          <el-table-column
            prop="guild_members"
            label="Guild Members"
            :width="110"
          />
        </el-table>
      </div>
      <div v-else-if="loading">
        loading...
      </div>
      <div v-else-if="!loading && data?.rows !== undefined">
        no data
      </div>
      <div />
    </template>
  </crud-table>
</template>
