<template>
  <crud-details-page
    api="cosmetics/skin"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Quality -->
        <crud-field-number api-field-name="Quality" />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.SkinType"
          :visible-options="['Card']"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- CardNameTag -->
        <crud-field-selector-client-strings
          label="Name Tag"
          api-field-name="CardNameTag"
        />
        <!-- IsGoldenCard -->
        <crud-field-switcher
          api-field-name="IsGoldenCard"
          label="GoldenCard"
          :grow-field="false"
        />
        <!-- IsDiamondCard -->
        <crud-field-switcher
          api-field-name="IsDiamondCard"
          label="DiamondCard"
          :grow-field="false"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
