<script setup></script>

<template>
  <crud-details-page
    api="cosmetics/mount-type"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- MountGroupType -->
        <crud-field-select
          api-field-name="MountGroupType"
          options-enum="enums.MountGroupType"
          required
        />
        <!-- AssetGroupIcon -->
        <crud-field-selector-files
          api-field-name="AssetGroupIcon"
          required
        />
      </fields-col>
      <fields-col :sm="16">
        <template
          v-for="i in 4"
          :key="i"
        >
          <crud-field-selector-skin
            :sm="12"
            :api-field-name="`Skin${i}`"
            :init-filters="{ Type: 'Horse' }"
          />
          <crud-field-selector-skin
            :sm="12"
            :api-field-name="`Skin${i}Replace`"
            :init-filters="{ Type: 'Horse' }"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
