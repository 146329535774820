<script setup>
function renderLoadedData(response) {
  return { items: response.rows, total: response.rows.length }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/getskinusagegameplay/'
    }"
    :top-actions="false"
    :action-col-settings="false"
    :static-api-params="{
      env: 'prod'
    }"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800'
    }"
    hide-columns-selector
    :api-pagination="false"
    :api-filtering="false"
    :api-sorting="false"
    :enable-cell-edit-dialogs="false"
    disable-multi-select-rows
    :render-loaded-data="renderLoadedData"
    :columns="{
      name: 'string:sort',
      count: 'int:sort'
    }"
    :columns-settings="{
      ID: {
        elAttr: {
          formatter: (row) => row.id
        },
        filterField: true
      },
      name: { width: 150, filterField: true },
      count: { width: 150, filterField: true }
    }"
  >
    <template #topLeft="{ searchFields }">
      <div class="ml-2 w-28">
        <crud-type-fields
          v-model:value-field="searchFields.skin_id"
          :min="0"
          :init-value="500"
          type-value-field="int"
          full-width
          label="Skin ID"
        />
      </div>
    </template>
    <template #cell_ID="{ row }">
      <router-link
        :to="{ name: 'gameplay-players-details', params: { id: row?.id }, query: { env: 'prod' } }"
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
      >
        {{ row?.id }}
      </router-link>
    </template>
  </crud-table>
</template>
